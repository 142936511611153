const baseUrl = process.env.API_URL + "/api/endpoints/"
import { handleResponse, handleError } from "./apiUtils"

export function getEndpointCount(auth) {
    return fetch(baseUrl + "all/", { headers: { Authorization: auth.accessToken } })
        .then(handleResponse)
        .catch(handleError)
}

export function getEndpoints(auth) {
    return fetch(baseUrl, { headers: { Authorization: auth.accessToken } })
        .then(handleResponse)
        .catch(handleError)
}

export function getEndpointById(auth, id) {
    return fetch(baseUrl + id, { headers: { Authorization: auth.accessToken } })
        .then(handleResponse)
        .catch(handleError)
}

export function getEndpointsByCustomer(auth, customer) {
    return fetch(baseUrl + "customer/" + customer, { headers: { Authorization: auth.accessToken } })
        .then(handleResponse)
        .catch(handleError)
}

export const saveEndpoint = (auth, endpoint) => {
    return fetch(baseUrl, {
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: auth.accessToken,
        },
        body: JSON.stringify(endpoint),
    })
        .then(handleResponse)
        .catch(handleError);
};

export const updateEndpoint = (auth, endpoint) => {
    return fetch(baseUrl, {
        method: "PUT",
        headers: {
            "content-type": "application/json",
            Authorization: auth.accessToken,
        },
        body: JSON.stringify(endpoint),
    })
        .then(handleResponse)
        .catch(handleError);
};

export const deleteEndpoint = (auth, id) => {
    return fetch(baseUrl + id, {
        method: "DELETE",
        headers: { Authorization: auth.accessToken, }
    })
        .then(handleResponse)
        .catch(handleError);
};

export function getEndpointsFromSearch(auth, searchTerm, filter, customer = null) {
    return customer === "null"
        ? fetch(`${baseUrl}search/${searchTerm}/${filter}`, { headers: { Authorization: auth.accessToken } })
            .then(handleResponse)
            .catch(handleError)
        : fetch(`${baseUrl}search/${searchTerm}/${filter}/${customer}`, { headers: { Authorization: auth.accessToken } })
            .then(handleResponse)
            .catch(handleError)
}