import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import LazySearchBar from "../common/LazySearchBar";
import { apiCallError } from "../../actions/apiStatusActions";
import Spinner from "../common/Spinner";
import { getEndpointCount } from "../../api/endpointApi";
import { fetchEndpoints, fetchEndpointsByCustomer, loadSearchedEndpoints, saveEndpoint } from "../../actions/endpointActions";
import { fetchCustomers } from "../../actions/customerActions";
import EndpointList from "./components/EndpointList";
import CreateEndpoint from "./components/CreateEndpoint";

const EndpointPage = ({ auth, endpoints, customers, apiCallsInProgress }) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [resultCount, setResultCount] = useState(0)
    const filters = ["Name", "URL"]

    useEffect(() => {
        refresh()
    }, [])

    useEffect(() => {
        updateResultCount(auth);
    }, [endpoints]);

    const updateResultCount = (auth) => {
        getEndpointCount(auth)
            .then((resultCount) => {
                setResultCount(resultCount)
            })
            .catch((error) => {
                dispatch(apiCallError(error))
            })
    }

    const refresh = () => {
        if (apiCallsInProgress === 0) {
            dispatch(fetchEndpoints(auth))
            dispatch(fetchCustomers(auth))
            updateResultCount(auth);
        }
    };

    const getSearchResult = (searchTerm, filter, customer = null) => {
        dispatch(loadSearchedEndpoints(auth, searchTerm, filter, customer))
    }

    const setSearchActive = () => { }

    const getEndpointsByCustomer = (customer) => {
        dispatch(fetchEndpointsByCustomer(auth, customer))
    }

    const saveEndpointHandler = (endpoint) => {
        dispatch(saveEndpoint(auth, endpoint))
    }

    return (
        <div className="endpoints">
            <h2 className="title">Endpoints</h2>
            <LazySearchBar
                filters={filters}
                refresh={refresh}
                customers={customers}
                resultCount={resultCount}
                getSearchResult={getSearchResult}
                setSearchActive={setSearchActive}
                getObjectByCustomer={getEndpointsByCustomer}
                apiCallsInProgress={apiCallsInProgress}
            />
            <div className="create-object-div">
                <CreateEndpoint
                    show={show}
                    setShow={setShow}
                    saveEndpoint={saveEndpointHandler}
                />
            </div>
            {endpoints.length === 0 && apiCallsInProgress > 0 ? (
                <Spinner />
            ) : (
                <div>
                    {apiCallsInProgress === 0 ? (
                        <>
                            <EndpointList />
                        </>
                    ) : (
                        <Spinner />
                    )}
                </div>
            )}
        </div>
    )
}

EndpointPage.propTypes = {
    auth: PropTypes.object.isRequired,
    endpoints: PropTypes.array.isRequired,
    customers: PropTypes.array.isRequired,
    apiCallsInProgress: PropTypes.number.isRequired,
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        endpoints: state.endpoints,
        customers: state.customers,
        apiCallsInProgress: state.apiCallsInProgress,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EndpointPage)