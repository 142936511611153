import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const dashboardReducer = (state = initialState.dashboard, action) => {
  switch (action.type) {
    case types.LOAD_DASHBOARD_SUCCESS:
      return {
        ...state,
        deviceCount: action.payload.dashboard.Dashboard.DeviceCount,
        forwards: action.payload.dashboard.Dashboard.ForwardCount,
        rawMessages: action.payload.dashboard.Dashboard.MessageCount,
        lastMsg: action.payload.dashboard.Dashboard.LastMsg,
        weekly: action.payload.dashboard.Messages,
        invalidmsg: action.payload.dashboard.RawJSON,
        insync: action.payload.dashboard.InSync,
      };

    default:
      return { ...state };
  }
};

export default dashboardReducer;
