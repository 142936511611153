import * as types from "../actions/actionsTypes";
import deviceForwardReducer from "./deviceForwardReducer";
import deviceModemRequestReducer from "./deviceModemRequestReducer";
import deviceSettingReducer from "./deviceSettingReducer";
import initialState from "./initialState";

const deviceDetailsReducer = (state = initialState.deviceDetails, action) => {
  switch (action.type) {
    case "LOADING_DEVICE":
      return {
        ...state,
        isLoading: true,
      };
    case types.NEW_RAWMESSAGE_DISPATCHED_TO_STATE: {
      let newRawMessages = [...state.rawMessages];
      newRawMessages.unshift(action.payload.deviceRawMessage);

      return {
        ...state,
        rawMessages: newRawMessages,
      };
    }
    case types.LOAD_DEVICE_DETAILS_SUCCESS:
      var ep0 = JSON.parse(action.payload.device.NewConfiguration.endpoint0JSON)
      var ep1 = JSON.parse(action.payload.device.NewConfiguration.endpoint1JSON)
      var ep2 = JSON.parse(action.payload.device.NewConfiguration.endpoint2JSON)

      var oldEp0 = JSON.parse(action.payload.device.CurrentConfiguration.endpoint0JSON)
      var oldEp1 = JSON.parse(action.payload.device.CurrentConfiguration.endpoint1JSON)
      var oldEp2 = JSON.parse(action.payload.device.CurrentConfiguration.endpoint2JSON)
      var x = {
        ...state,
        device: action.payload.device.Device,
        currentConfig: {
          ...action.payload.device.CurrentConfiguration,
          message0: { ...oldEp0, index: 0, protocol: action.payload.device.CurrentConfiguration.endpoint0Protocol },
          message1: { ...oldEp1, index: 1, protocol: action.payload.device.CurrentConfiguration.endpoint1Protocol },
          message2: { ...oldEp2, index: 2, protocol: action.payload.device.CurrentConfiguration.endpoint2Protocol },
        },
        newConfig: {
          ...action.payload.device.NewConfiguration,
          message0: { ...ep0, index: 0, protocol: action.payload.device.NewConfiguration.endpoint0Protocol },
          message1: { ...ep1, index: 1, protocol: action.payload.device.NewConfiguration.endpoint1Protocol },
          message2: { ...ep2, index: 2, protocol: action.payload.device.NewConfiguration.endpoint2Protocol },
        },
        state: action.payload.device.State,
        isLoading: false,
      };
      delete x.currentConfig.endpoint0Protocol
      delete x.currentConfig.endpoint1Protocol
      delete x.currentConfig.endpoint2Protocol
      delete x.newConfig.endpoint0Protocol
      delete x.newConfig.endpoint1Protocol
      delete x.newConfig.endpoint2Protocol
      delete x.currentConfig.endpoint0JSON
      delete x.currentConfig.endpoint1JSON
      delete x.currentConfig.endpoint2JSON
      delete x.newConfig.endpoint0JSON
      delete x.newConfig.endpoint1JSON
      delete x.newConfig.endpoint2JSON
      return x
    case types.LOAD_DEVICE_CURRENT_CONFIG_SUCCESS:
      return {
        ...state,
        config: action.payload.config,
        isLoading: false,
      };
    case types.LOAD_DEVICE_STATE_SUCCESS:
      return {
        ...state,
        state: action.payload.deviceState,
        isLoading: false,
      };
    case types.LOAD_DEVICE_RAW_MESSAGES_SUCCESS:
      return {
        ...state,
        rawMessages: action.payload.rawMessages,
        isLoading: false,
      };
    case types.LOAD_FIRST_BATCH_DEVICE_RAW_MESSAGES_SUCCESS:
      return {
        ...state,
        rawMessages: action.payload.rawMessages,
        isLoading: false,
      };
    case types.LOAD_BATCH_DEVICE_RAW_MESSAGES_SUCCESS: {
      let result = [...state.rawMessages, ...action.payload.rawMessages];
      return {
        ...state,
        rawMessages: result,
        isLoading: false,
      };
    }
    case types.LOAD_CONFIGURATION_GROUPS_CUSTOMER_SUCCESS:
      return {
        ...state,
        configurationGroups: action.payload.configurationGroups,
      };
    case types.LOAD_DEVICE_CUSTOMER_HISTORY_SUCCESS:
      return {
        ...state,
        customerHistory: action.payload.customerHistory,
        isLoading: false,
      };
    case types.SAVE_DEVICE_CUSTOMER_CONNECTION_SUCCESS:
      return {
        ...state,
        device: action.payload.device,
      };
    case types.SAVE_CONFIGURATION_GROUP_FOR_DEVICE_SUCCESS:
      return {
        ...state,
        device: action.payload.device,
      };
    case types.REMOVE_DEVICE_CONFIGURATION_GROUP_SUCCESS:
      return {
        ...state,
        device: action.payload.device,
      };
    case types.LOAD_DEVICE_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: deviceSettingReducer(state.settings, action),
        isLoading: false,
      };
    case types.SAVE_DEVICE_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: deviceSettingReducer(state.settings, action),
        isLoading: false,
      };
    case types.UPDATE_DEVICE_SETTING_SUCCESS:
      return {
        ...state,
        settings: deviceSettingReducer(state.settings, action),
        isLoading: false,
      };
    case types.REMOVE_DEVICE_SETTING_SUCCESS:
      return {
        ...state,
        settings: deviceSettingReducer(state.settings, action),
        isLoading: false,
      };
    case types.REMOVE_UNPROCESSED_DEVICE_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: deviceSettingReducer(state.settings, action),
        isLoading: false,
      };
    case types.LOAD_DEVICE_FORWARDS_SUCCESS:
      return {
        ...state,
        forwards: deviceForwardReducer(state.forwards, action),
        isLoading: false,
      };
    case types.SAVE_DEVICE_FORWARD_SUCCESS:
      return {
        ...state,
        forwards: deviceForwardReducer(state.forwards, action),
      };
    case types.UPDATE_DEVICE_FORWARD_SUCCESS:
      return {
        ...state,
        forwards: deviceForwardReducer(state.forwards, action),
      };
    case types.REMOVE_DEVICE_FORWARD_SUCCESS:
      return {
        ...state,
        forwards: deviceForwardReducer(state.forwards, action),
      };
    case types.LOAD_DEVICE_MODEM_REQUESTS_SUCCESS:
      return {
        ...state,
        modemRequests: deviceModemRequestReducer(state.modemRequests, action),
        isLoading: false,
      };
    case types.SAVE_DEVICE_MODEM_REQUEST_SUCCESS:
      return {
        ...state,
        modemRequests: deviceModemRequestReducer(state.modemRequests, action),
      };
    case types.UPDATE_DEVICE_MODEM_REQUEST_SUCCESS:
      return {
        ...state,
        modemRequests: deviceModemRequestReducer(state.modemRequests, action),
      };
    case types.REMOVE_DEVICE_MODEM_REQUEST_SUCCESS:
      return {
        ...state,
        modemRequests: deviceModemRequestReducer(state.modemRequests, action),
      };
    default:
      return state;
  }
};

export default deviceDetailsReducer;