import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Redux
import { connect, useDispatch } from "react-redux";
import { loadFirmwareFlags, loadFirmwares, massUpdateFirmware, massUpdateBooter } from "../../actions/firmwareActions";
import { fetchCustomers } from "../../actions/customerActions";

// Components
import FirmwareList from "./FirmwareList";
import CreateFirmware from "./components/CreateFirmware";
import Spinner from "../common/Spinner";
import SearchBar from "../common/SearchBar";

// Styling
import { Card } from "react-bootstrap";
import { fetchDeviceTypes } from "../../actions/deviceTypeActions";
import MassUpdateFirmware from "./components/MassUpdateFirmware";
import MassUpdateBooter from "./components/MassUpdateBooter";

const FirmwarePage = ({ auth, apiCallsInProgress, firmwares }) => {
  const dispatch = useDispatch();
  const firmwareslist = [...firmwares];
  const [searchResult, setSearchResult] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const filters = ["Version", "Filename", "Release", "Created", "Active"];
  const [show, setShow] = useState(false);
  const [showMassUpdate, setShowMassUpdate] = useState(false);
  const [showMassUpdateBooter, setShowMassUpdateBooter] = useState(false)

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    // console.log("getting")
    dispatch(loadFirmwares(auth));
    dispatch(loadFirmwareFlags(auth));
    dispatch(fetchDeviceTypes(auth));
    dispatch(fetchCustomers(auth))
  };
  
  const onSubmitMassBooterUpdate = (customer, firmware) => {
    const request = { customer: customer, firmware: firmware }
    dispatch(massUpdateBooter(auth, request))
  }

  const onSubmitMassFirmwareUpdate = (customer, firmware) => {
    const request = { customer: customer, firmware: firmware }
    dispatch(massUpdateFirmware(auth, request))
  }
  return (
    <div className="firmware">
      <h2 className="title">Firmware</h2>
      <SearchBar
        filters={filters}
        setSearchResult={setSearchResult}
        searchResult={searchResult}
        all={firmwareslist}
        refresh={refresh}
        setSearchActive={setSearchActive}
      />
      <div className="create-object-div">
        <CreateFirmware show={show} setShow={setShow} />
        <MassUpdateFirmware show={showMassUpdate} setShow={setShowMassUpdate} onSubmitMassFirmwareUpdate={onSubmitMassFirmwareUpdate}/>
        <MassUpdateBooter show={showMassUpdateBooter} setShow={setShowMassUpdateBooter} onSubmitMassBooterUpdate={onSubmitMassBooterUpdate}/>
      </div>
      <br />
      {firmwareslist.length === 0 || apiCallsInProgress > 0 ? (
        <Spinner />
      ) : (
        <Card>
          {searchResult.length === 0 && searchActive == false ? (
            <FirmwareList firmwares={firmwareslist} />
          ) : (
            <FirmwareList firmwares={searchResult} />
          )}
        </Card>
      )}
    </div>
  );
};

FirmwarePage.propTypes = {
  auth: PropTypes.object.isRequired,
  firmwares: PropTypes.array.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    firmwares: state.firmwares,
    apiCallsInProgress: state.apiCallsInProgress,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FirmwarePage);
