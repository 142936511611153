import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Redux
import { useDispatch, connect } from "react-redux";
import { saveDeviceSettings } from "../../../actions/deviceSettingActions";
import { getDeviceById } from "../../../actions/deviceActions";

// Styling
import { Form, Row, Col, Button } from "react-bootstrap";

// Components
import Spinner from "../../common/Spinner";

const DeviceThresholdsPaneV2 = ({
  auth,
  device,
  currentConfig,
  newConfig,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const [changedThresholds, setChangedThresholds] = useState({});
  const thresholds = [
    { name: "emergencyBrakeThreshold", val: "m/s^2" },
    { name: "harshAccelerationThreshold", val: "m/s^2" },
    { name: "accelerationThreshold", val: "m/s^2" },
    { name: "deccelerationThreshold", val: "m/s^2" },
    { name: "harshBrakeThreshold", val: "m/s^2" },
    { name: "overSpeedThreshold", val: "km/h" },
    { name: "highRPMThreshold", val: "rev/min" },
    { name: "highPedalThreshold", val: "%" },
    { name: "harshBrakeDetectionSpeedThreshold", val: "km/h" },
    { name: "coastableDistanceCountingDistanceDelay", val: "m" },
    { name: "cruisableTimeCountingTimeDelay", val: "s" },
    { name: "overRPMCountingTimeDelay", val: "s" },
    { name: "overSpeedCountingTimeDelay", val: "s" },
    { name: "ignitionOffDelay", val: "s" },
    { name: "idleTimeDelay", val: "s" },
    { name: "longitudinalMovementThreshold", val: "m/s^2" },
    { name: "lateralMovementThreshold", val: "m/s^2" },
    { name: "ConfigGroupId", val: null },
    { name: "ConfigGroupVersion", val: null },
  ];

  useEffect(() => {
    // document.getElementById("thresholds-form-id").reset();
    dispatch(getDeviceById(auth, device.Id));
  }, []);

  const inputHandler = (e) => {
    const value = e.target.value;
    setChangedThresholds({
      ...changedThresholds,
      [e.target.name]: value,
    });
  };

  const saveThresholdsHandler = async (e) => {
    e.preventDefault();
    var changeList = [];
    for (const property in changedThresholds) {
      if (newConfig[property] == changedThresholds[property]) continue;
      var obj = {
        SettingName: property,
        Value: changedThresholds[property],
      };
      changeList.push(obj);
    }

    // console.log(changeList);

    dispatch(
      saveDeviceSettings(auth, device.Id, device.IMEI, {
        SettingsList: changeList,
      })
    );
  };

  const isViewer = auth.user.Role ==="Viewer";

  return (
    <>
      {!isLoading ? (
        <Form id="thresholds-form-id">
          {thresholds.map((threshold) => {
            return (
              <Form.Group
                as={Row}
                key={threshold.name}
                controlId="formThresholdSetting"
              >
                <Form.Label column sm="3">
                  <h6>
                    {(threshold.val === null) ? (threshold.name) : (threshold.name + " (" + threshold.val + ")")}
                    <i>
                      {currentConfig[threshold.name] == newConfig[threshold.name]
                        ? ""
                        : " (Currently " + currentConfig[threshold.name] + ")"}
                    </i>
                  </h6>
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    name={threshold.name}
                    onChange={inputHandler}
                    defaultValue={newConfig[threshold.name]}
                  />
                </Col>
              </Form.Group>
            );
          })}
          <hr />
          <Form.Group>
            <Row>
              <Col sm="11">
                <Button variant="outline-warning" type="submit">
                  Back
                </Button>
              </Col>
              <Col sm="1">
                {!isViewer && (
                  <Button
                    onClick={saveThresholdsHandler}
                    variant="success"
                    type="submit"
                  >
                    Save
                  </Button>
                  )}
              </Col>
            </Row>
          </Form.Group>
        </Form>
      ) : (
        <Spinner />
      )}
    </>
  );
};

DeviceThresholdsPaneV2.propTypes = {
  auth: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
  currentConfig: PropTypes.object.isRequired,
  newConfig: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    device: state.deviceDetails.device,
    currentConfig: state.deviceDetails.currentConfig,
    newConfig: state.deviceDetails.newConfig,
    isLoading: state.deviceDetails.isLoading,
  };
}
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceThresholdsPaneV2);
