import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, Col, Row, Button } from "react-bootstrap";

const MessageURLControlRow = ({
    index,
    message,
    setMessage,
    saveMessageHandler,
    disableOptions,
    isViewer,
    endpoints,
    currentMessage,
    apiCallsInProgress,
    auth,
}) => {
    var newMessage = message;

    const endpointHandler = (endpoint) => {
        const selectedEndpoint = endpoints.find((e) => e.Id === endpoint.target.value)
        if (selectedEndpoint) {
            newMessage = { ...newMessage, URL: selectedEndpoint.URL, Protocol: selectedEndpoint.Protocol, endpointID: selectedEndpoint.Id }
            setMessage(newMessage)
        }
    }

    const defaultEndpoint = {
        URL: "d.telematiqs.com:5000/api/v1/devices",
        Protocol: 1,
        Id: ""
    }

    const isAdmin = auth.user.Role === "Admin"
    const endps = endpoints ? [...endpoints].sort((a, b) => (a.Name.toUpperCase() < b.Name.toUpperCase() ? -1 : 1)) : null

    return (
        <>
            {apiCallsInProgress === 0 ? (
                <Form.Group as={Row}>
                    <Form.Label column sm="2">
                        <h6>{"Endpoint " + index}{+ !currentMessage || message.URL == currentMessage.URL ? "" : "*"}:</h6>
                    </Form.Label>
                    <Col>
                        <Form.Control
                            name={"Protocol" + index}
                            as="select"
                            disabled={disableOptions() || isViewer}
                            defaultValue={message.endpointID}
                            onChange={(e) => endpointHandler(e)}
                        >

                            {message.endpointID && message.endpointID == "00000000-0000-0000-0000-000000000000" ? (
                                <option key={null} value={defaultEndpoint}>
                                    {"unknown - " + message.URL}
                                </option>
                            ) : (null)
                            }
                            {endps && endps.map((endpoint) => {
                                var prot = "unknown";
                                switch (endpoint.Protocol) {
                                    case 1:
                                        prot = "HTTP";
                                        break;
                                    case 3:
                                        prot = "TCP";
                                        break;
                                }
                                return (
                                    <option key={endpoint.Id} value={endpoint.Id}>
                                        {endpoint.Name + (isAdmin ? (" - " + endpoint.URL) : "") + " (" + prot + ")"}
                                    </option>
                                )
                            })

                            }

                        </Form.Control>
                    </Col>
                    {/* <Col sm="8">
                    <Form.Control
                        name={index}
                        disabled={disableOptions() || isViewer}
                        type="string"
                        defaultValue={newMessage.URL}
                        onChange={(e) => inputHandler(e)}
                    />
                </Col> */}
                    <Col sm="1">
                        {!isViewer && !disableOptions() && (
                            <Button
                                variant="success"
                                onClick={(e) => saveMessageHandler(e)}
                            >
                                Save
                            </Button>
                        )}
                    </Col>
                </Form.Group>
            ) : (
                null
            )}
        </>
    )
}

MessageURLControlRow.propTypes = {
    index: PropTypes.number.isRequired,
    message: PropTypes.object.isRequired,
    setMessage: PropTypes.func.isRequired,
    saveMessageHandler: PropTypes.func.isRequired,
    disableOptions: PropTypes.func.isRequired,
    isViewer: PropTypes.bool.isRequired,
    endpoints: PropTypes.array.isRequired,
    currentMessage: PropTypes.object,
    auth: PropTypes.object.isRequired,
    apiCallsInProgress: PropTypes.number.isRequired
}
function mapStateToProps(state) {
    return {
        apiCallsInProgress: state.apiCallsInProgress,
        auth: state.auth
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MessageURLControlRow);