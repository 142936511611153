import React, { useEffect, useState } from "react";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Styling
import { Modal, Button, Form, Container, Row, Col } from "react-bootstrap";

const ChangeForwardModal = ({
  device,
  show,
  setShow,
  selectedForward,
  setSelectedForward,
  saveForwardHandler,
  forwards,
}) => {
  // const [protocolList, setProtocolList] = useState([]);
  // const [updatedForward, setUpdatedForward] = useState({});
  const [listForward, setListForward] = useState();
  const [disabled, setDisabled] = useState(true);
  const handleClose = () => setShow(false);

  useEffect(() => {
    setListForward(
      forwards.find((item) => item.Id === selectedForward.ForwardId)
    );
  }, [selectedForward, forwards]);

  const inputHandler = (e) => {
    if (e.target.name === "ForwardId") {
      setListForward(forwards.find((item) => item.Id == e.target.value));
    }

    setSelectedForward({
      ...selectedForward,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (selectedForward.ForwardId === undefined) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [selectedForward]);

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Form>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {device.IMEI}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col xs={12} md={4}>
                  <Form.Label>
                    <h6>Message Type: </h6>
                  </Form.Label>
                </Col>
                <Col xs={6} md={4}>
                  <Form.Control
                    plaintext
                    readOnly
                    defaultValue={selectedForward.MessageType}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <Form.Label>
                    <h6>Forward : </h6>
                  </Form.Label>
                </Col>
                <Col xs={6} md={4}>
                  <Form.Control
                    as="select"
                    name="ForwardId"
                    defaultValue={selectedForward.ForwardId}
                    onChange={inputHandler}
                  >
                    <option value={"null"} key={"null"}>
                      ---
                    </option>
                    {forwards.map((forward) => {
                      return (
                        forward.MessageTypes && 
                        forward.MessageTypes.length > 0 && 
                        forward.MessageTypes[0].MessageType === selectedForward.MessageType ? (
                          <option key={forward.Id} value={forward.Id}>
                            {forward.Name}
                          </option>
                        ) : null
                      );
                    })}
                  </Form.Control>
                </Col>
              </Row>
              {listForward && (
                <>
                  <br />
                  <Row>
                    <Col xs={12} md={4}>
                      <h6>IP Address :</h6>{" "}
                    </Col>
                    <Col xs={6} md={4}>
                      {listForward.IP}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={4}>
                      <h6>Port :</h6>{" "}
                    </Col>
                    <Col xs={6} md={4}>
                      {listForward.Port}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={4}>
                      <h6>Status :</h6>{" "}
                    </Col>
                    <Col xs={6} md={4}>
                      {listForward.Status}
                    </Col>
                  </Row>
                </>
              )}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={saveForwardHandler}
              disabled={disabled}
              variant="success"
              type="submit"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

ChangeForwardModal.propTypes = {
  device: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  selectedForward: PropTypes.object.isRequired,
  setSelectedForward: PropTypes.func.isRequired,
  saveForwardHandler: PropTypes.func.isRequired,
  forwards: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeForwardModal);
