import React, { useEffect, useState } from "react"
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import LazySearchBar from "../common/LazySearchBar";
import { Button, Card } from "react-bootstrap";
import Spinner from "../common/Spinner";
import { getSettingsProfiles, fetchSettingsProfilesByCustomer, fetchSettingsProfilesByName } from "../../actions/settingsProfileActions";
import { fetchCustomers } from "../../actions/customerActions"
import CreateSettingsProfile from "./components/CreateSettingsProfile";
import SettingsProfilesList from "./components/SettingsProfilesList";
import { loadFirmwares } from "../../actions/firmwareActions";
import { fetchEndpoints } from "../../actions/endpointActions";

const SettingsProfilesPage = ({
    auth,
    customers,
    apiCallsInProgress,
    settingsProfiles,
    endpoints
}) => {
    const dispatch = useDispatch();
    const filters = ["Name"];
    const [show, setShow] = useState(false);
    const [resultCount, setResultCount] = useState(0);
    const initialValues = {
        Name: "",
        BooterVersion: "0",
        applicationVersion: "0",
        iButton: "False",
        iButtonClearInterval: "300",
        PrivateSwitchEnabled: "True",
        TachoLiveEnabled: "False",
        minuteRuleEnabled: "True",
        TachoRDLEnabled: "False",
        External5VEnabled: "True",
        drivingStyleEventsEnabled: "False",
        timedIntervalRelativeToSpeed: "False",
        highResolutionRecordingEnabled: "False",
        CANWakeUpEnabled: "True",
        standbyMessageInterval: "7200",
        timedMessageInterval: "120",
        chargingMessageInterval: "900",
        message0:
        {
            index: 0,
            URL: "d.telematiqs.com:5000/api/v1/devices",
            TIME: 3,
            SOT: 35,
            EOT: 35,
            CHG: 3,
            POR: 1280,
            WAKE: 1280,
            STB: 3,
            MOV: 0,
            LOWBV: 3,
            PZ: 0,
            DWL: 0,
            LOG: 0,
            DS: 163,
            DIAG: 1792,
            TL: 0,
            REQ: 65535,
            protocol: 1
        },
        message1:
        {
            index: 1,
            URL: "d.telematiqs.com:5000/api/v1/devices",
            TIME: 0,
            SOT: 0,
            EOT: 0,
            CHG: 0,
            POR: 0,
            WAKE: 0,
            STB: 0,
            MOV: 0,
            LOWBV: 0,
            PZ: 0,
            DWL: 0,
            LOG: 0,
            DS: 0,
            DIAG: 0,
            TL: 0,
            REQ: 0,
            protocol: 1
        },
        message2:
        {
            index: 2,
            URL: "d.telematiqs.com:5000/api/v1/devices",
            TIME: 0,
            SOT: 0,
            EOT: 0,
            CHG: 0,
            POR: 0,
            WAKE: 0,
            STB: 0,
            MOV: 0,
            LOWBV: 0,
            PZ: 0,
            DWL: 0,
            LOG: 0,
            DS: 0,
            DIAG: 0,
            TL: 0,
            REQ: 0,
            protocol: 1
        },
    }
    const [settingsProfile, setSettingsProfile] = useState(initialValues)

    useEffect(() => {
        refresh();
    }, [])

    const refresh = () => {
        if (apiCallsInProgress === 0) {
            dispatch(fetchCustomers(auth));
            dispatch(getSettingsProfiles(auth))
            dispatch(loadFirmwares(auth))
            dispatch(fetchEndpoints(auth))
            setResultCount(settingsProfiles.length)
        }
    }

    const getSearchResult = (searchterm, name, customer) => {
        // console.log(searchterm)
        dispatch(fetchSettingsProfilesByName(auth, searchterm, customer))
    }
    const setSearchActive = () => { }
    const getObjectByCustomer = (customer) => {
        dispatch(fetchSettingsProfilesByCustomer(auth, customer))
    }

    const handleButton = () => {
        setShow(true)
        setSettingsProfile(initialValues)
    }
    const isViewer = auth.user.Role === "Viewer";
    return (
        <div className="settingsProfiles">
            <h2 className="title">SettingsProfiles</h2>
            <LazySearchBar
                filters={filters}
                refresh={refresh}
                customers={customers}
                resultCount={resultCount}
                getSearchResult={getSearchResult}
                setSearchActive={setSearchActive}
                getObjectByCustomer={getObjectByCustomer}
                apiCallsInProgress={apiCallsInProgress}
            />
            <div className="create-object-div">
                {!isViewer &&
                    <div className="create-config">

                        <Button className="create-object-button" onClick={handleButton}>
                            New profile
                        </Button>
                    </div>
                }
                {/* <CreateSettingsProfile
                    show={show}
                    setShow={setShow}
                    refresh={refresh}
                    settingsProfile={settingsProfile}
                    setSettingsProfile={setSettingsProfile}
                /> */}
            </div>
            <Card>
                {settingsProfiles ? (
                    settingsProfiles.length == 0 ? (
                        <>No settings profiles</>
                    ) : (
                        <SettingsProfilesList />
                    )
                ) : (
                    <Spinner />
                )}
            </Card>
            {
                settingsProfile && (
                    <CreateSettingsProfile
                        show={show}
                        setShow={setShow}
                        settingsProfile={settingsProfile}
                        setSettingsProfile={setSettingsProfile}
                        endpoints={endpoints}
                    />
                )
            }
        </div>
    )
}

SettingsProfilesPage.propTypes = {
    auth: PropTypes.object.isRequired,
    customers: PropTypes.array.isRequired,
    settingsProfiles: PropTypes.array.isRequired,
    apiCallsInProgress: PropTypes.number.isRequired,
    endpoints: PropTypes.array.isRequired,
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        customers: state.customers,
        settingsProfiles: state.settingsProfiles,
        apiCallsInProgress: state.apiCallsInProgress,
        endpoints: state.endpoints,
    }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsProfilesPage)