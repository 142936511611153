import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, connect } from "react-redux";
import { fetchForwards } from "../../../actions/forwardActions";
import {
    getForwardsByDevice,
    removeForwardDevice, saveForwardDevice, updateForwardDevice,
} from "../../../actions/deviceForwardActions";
import Confirmation from "../../common/Confirmation";
import ChangeForwardModal from "./ChangeForwardModal";
import Spinner from "../../common/Spinner";
import { Button, Table } from "react-bootstrap";
import "./ForwardsPane.css";
import EditIcon from "../../common/icons/pen-to-square-solid.svg";
import DeleteIcon from "../../common/icons/trash-can-solid.svg";

const DeviceForwardsPane = ({
    auth,
    device,
    forwards,
    deviceForwards,
    isLoading,
}) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [selectedForward, setSelectedForward] = useState({});

    useEffect(() => {
        getForwardsDevice();
    }, []);

    const getForwardsDevice = () => {
        dispatch(fetchForwards(auth));
        dispatch(getForwardsByDevice(auth, device.IMEI));
    };

    const selectForward = (e) => {
        const forward = deviceForwards.find((item) => item.MessageType === e);
        setSelectedForward({ ...forward });
    };

    const handleShow = () => {
        setShow(true);
    };

    const removeForwardHandler = (e) => {
        const forwardToRemove = deviceForwards.find((item) => item.Id === e);
        (() => dispatch(removeForwardDevice(auth, e, forwardToRemove.MessageType)))();
        setShow(false);
    };

    const showModalHandler = (e) => {
        handleShow();
        selectForward(e);
    }

    const saveForwardHandler = (e) => {
        e.preventDefault();

        const forwardToSave = { ...selectedForward, IMEI: device.IMEI };

        if (forwardToSave.Protocol !== "non") {
            (() => dispatch(updateForwardDevice(auth, forwardToSave)))();
        } else {
            (() => dispatch(saveForwardDevice(auth, forwardToSave)))();
        }
        setShow(false);
    };
    const devEps = [...deviceForwards].sort((a, b) => (a.MessageType > b.MessageType ? 1 : -1))


    return (
        <>
            {!isLoading ? (
                <>
                    <Table striped hover responsive>
                        <thead>
                            <tr>
                                <th>Message</th>
                                <th>Protocol</th>
                                <th>Name</th>
                                <th>Server</th>
                                <th>Port</th>
                                <th>Username</th>
                                <th>Password</th>
                                <th>API_KEY</th>
                                <th>Authorization</th>
                                <th>Status</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {devEps.map((forward) => {

                                const isViewer = auth.user.Role === "Viewer";
                                var messageType = ""
                                switch (forward.MessageType) {
                                    case "RT":
                                        messageType = "Tracking"
                                        break;
                                    case "TD":
                                        messageType = "TripData"
                                        break;
                                    case "TL":
                                        messageType = "TachoLive"
                                        break;
                                    case "TC":
                                        messageType = "RDL"
                                        break;
                                    default:
                                        messageType = forward.MessageType
                                        break;
                                }
                                return (
                                    <tr key={forward.MessageType} className="table-row">
                                        <td>{messageType}</td>
                                        <td>{forward.Protocol}</td>
                                        <td>{forward.Name}</td>
                                        <td>{forward.IP}</td>
                                        <td>{forward.Port}</td>
                                        <td>{forward.Username}</td>
                                        <td>{forward.Password}</td>
                                        <td>{forward.API_KEY}</td>
                                        <td>{forward.Authorization}</td>
                                        <td>{forward.Status}</td>
                                        <td>
                                            {!isViewer && (
                                                <Button className="shadow-sm border-secondary" variant="light" disabled={forward.MessageType == "TC"}
                                                    onClick={() => {
                                                        showModalHandler(forward.MessageType)
                                                    }}>
                                                    <img src={EditIcon} alt="Edit" />
                                                </Button>
                                            )}
                                        </td>
                                        <td>
                                            {!isViewer && (
                                                <Button className="shadow-sm border-secondary" variant="light" disabled={forward.MessageType == "TC"}>
                                                    <Confirmation
                                                        buttonText={<img src={DeleteIcon} alt="Delete" />}
                                                        description={`Are you sure you want to remove this forward?`}
                                                        onConfirm={() => removeForwardHandler(forward.Id)}
                                                    />
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    {selectedForward && (
                        <ChangeForwardModal
                            device={device}
                            show={show}
                            setShow={setShow}
                            selectedForward={selectedForward}
                            setSelectedForward={setSelectedForward}
                            saveForwardHandler={saveForwardHandler}
                            removeForwardHandler={removeForwardHandler}
                            forwards={forwards}
                        />
                    )}
                </>
            ) : (
                <Spinner />
            )
            }
        </>
    );
};

DeviceForwardsPane.propTypes = {
    auth: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    forwards: PropTypes.array.isRequired,
    deviceForwards: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        device: state.deviceDetails.device,
        forwards: state.forwards,
        deviceForwards: state.deviceDetails.forwards,
        isLoading: state.deviceDetails.isLoading,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceForwardsPane);
