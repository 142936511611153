import React, {  useState } from "react";
import PropTypes from "prop-types";

import { connect, useDispatch } from "react-redux";
import EditIcon from "../../common/icons/pen-to-square-solid.svg";

import UpdateForward from "./UpdateForward";
import {
    fetchForwards,
    // fetchForwardById,
    deleteForward,
    updateForward,
} from "../../../actions/forwardActions";
import { SortedTable } from "../../common/SortedTable";
import SortButton from "../../common/SortButton";
import { Table, Button } from "react-bootstrap";
import Confirmation from "../../common/Confirmation";
import DeleteIcon from "../../common/icons/trash-can-solid.svg";

// import { fetchForwardById } from "../../../actions/customerActions";

const ForwardList = ({ auth, forwards, customers, protocols, messageTypes, }) => {


    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const { list, requestSort, sortConfig } = SortedTable(forwards);
    const [selectedForward, setSelectedForward] = useState(null);

    // useEffect(() => {
    //     console.log("Forwards: ", forwards);
    //     console.log("Customers: ", customers);
    //     console.log("Protocols: ", protocols);
    //     console.log("Message Types: ", messageTypes);
    // }, [forwards, customers, protocols, messageTypes]);


    // useEffect(() => {
    //     console.table(forwards);
    // }, [forwards]);

    const handleclose = () => {
        setShow(false);
        dispatch(fetchForwards(auth));
    };

    const selectForward = (e) => {
        setSelectedForward(
            forwards.find((forward) => {
                return forward.Id === e;
            })
        );
    };

    const updateForwardHandler = (forwardupdate) => {
        dispatch(updateForward(auth, forwardupdate.Id, forwardupdate));
        handleclose();
    };

    const removeForwardHandler = (e) => {
        const forward = forwards.find((item) => item.Id === e);
        dispatch(deleteForward(auth, e, forward.MessageType));
        setShow(false);
    };

    const showModalHandler = (e) => {
        setShow(true);
        selectForward(e);
    };

    return (
        <>
            <Table striped>
                <thead className="thead-inverse">
                    <tr>
                        <th>
                            Customer
                            <SortButton
                                name="Customer"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Name
                            <SortButton
                                name="Name"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            IP
                            <SortButton
                                name="IP"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Port
                            <SortButton
                                name="Port"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Protocol
                            <SortButton
                                name="Protocol"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Username
                            <SortButton
                                name="Username"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Password
                            <SortButton
                                name="Password"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            API_Key
                            <SortButton
                                name="API_KEY"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Authorization
                            <SortButton
                                name="Auth"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Status
                            <SortButton
                                name="Status"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Devices
                            <SortButton
                                name="amountdevices"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {list.length === 0 ? (
                        <tr>
                            <td colSpan="15" style={{ textAlign: "center" }}>
                                No forwards found
                            </td>
                        </tr>
                    ) : (
                        list.map((forward) => {
                            // console.log('Forward ${index}:', forward);
                            const isViewer = auth.user.Role === "Viewer"

                            return (
                                <tr className="table-row" key={forward.Id}>
                                    <td>{forward.Customer}</td>
                                    <td>{forward.Name}</td>
                                    <td>{forward.IP}</td>
                                    <td>{forward.Port}</td>
                                    <td>{forward.Protocols[0].Protocol}</td>
                                    <td>{forward.Username}</td>
                                    <td>{forward.Password}</td>
                                    <td>{forward.API_KEY}</td>
                                    <td>{forward.Auth}</td>
                                    <td>{forward.Status}</td>
                                    <td>{forward.Amountdevices}</td>
                                    <td>
                                        {!isViewer && (
                                            <Button className="shadow-sm border-secondary" variant="light" onClick={() => {
                                                showModalHandler(forward.Id)
                                            }}>
                                                <img
                                                    src={EditIcon}
                                                    alt="Edit"
                                                />
                                            </Button>
                                        )}
                                    </td>
                                    <td>
                                        {!isViewer && (
                                            <Button className="shadow-sm border-secondary" variant="light">
                                                <Confirmation
                                                    buttonText={
                                                        <img
                                                            src={DeleteIcon}
                                                            alt="Delete"
                                                        />
                                                    }
                                                    description={`Are you sure you want to remove this forward?`}
                                                    onConfirm={() => removeForwardHandler(forward.Id)}
                                                />
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>
            {selectedForward && (
                <UpdateForward
                    show={show}
                    setShow={setShow}
                    forward={selectedForward}
                    customers={customers}
                    protocols={protocols}
                    messageTypes={messageTypes}
                    updateForwardHandler={updateForwardHandler}
                />
            )}
        </>
    );
};

ForwardList.propTypes = {
    auth: PropTypes.object.isRequired,
    forwards: PropTypes.array.isRequired,
    customers: PropTypes.array.isRequired,
    protocols: PropTypes.array.isRequired,
    messageTypes: PropTypes.array.isRequired
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        forwards: state.forwards,
        customers: state.customers,
        protocols: state.protocols,
        messageTypes: state.messageTypes,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ForwardList);
