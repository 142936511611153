import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const forwardReducer = (state = initialState.forwards, action) => {
  switch (action.type) {
    case types.LOAD_FORWARDS_SUCCESS:
      return action.payload.forwards;
    case types.SAVE_FORWARD_SUCCESS:
      return [...state, action.payload.forward];
    case types.UPDATE_FORWARD_SUCCESS:
      return [
        ...state.filter(
          (forward) => forward.Id != action.payload.forward.Id
        ),
        action.payload.forward,
      ];
    case types.LOAD_SEARCH_RESULT_FORWARD_SUCCESS:
      return action.payload.forwards;
    case types.LOGOUT:
      return initialState.forwards;
    default:
      return state;
  }
};

export default forwardReducer;
