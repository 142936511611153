import * as types from "./actionsTypes";
import * as forwardApi from "../api/forwardApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";

export const fetchForwards = (auth) => async (dispatch) => {
    dispatch(beginApiCall());

    forwardApi
        .getForwards(auth)
        .then((forwards) => {

            dispatch({
                type: types.LOAD_FORWARDS_SUCCESS,
                payload: { forwards: forwards },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const fetchMessageTypes = (auth) => async (dispatch) => {
    dispatch(beginApiCall());

    forwardApi
        .getMessageTypes(auth)
        .then((messageTypes) => {
            dispatch({
                type: types.LOAD_MESSAGE_TYPES_SUCCESS,
                payload: {
                    messageTypes: messageTypes,
                }
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}

export const fetchProtocols = (auth) => async (dispatch) => {
    dispatch(beginApiCall());

    forwardApi
        .getProtocols(auth)
        .then((protocols) => {
            dispatch({
                type: types.LOAD_PROTOCOLS_SUCCESS,
                payload: {
                    protocols: protocols,
                }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        });
}

export const fetchForwardId = (auth, id) => async (dispatch) => {
    dispatch(beginApiCall());

    forwardApi
        .getForwardById(auth, id)
        .then((res) => {
            dispatch({
                type: types.LOAD_FORWARD_SUCCESS,
                payload: {
                    forward: res
                },
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}

export const fetchForwardsByCustomer = (auth, id) => async (dispatch) => {
    dispatch(beginApiCall());

    forwardApi
        .getForwardsByCustomer(auth, id)
        .then((forwards) => {
            dispatch({
                type: types.LOAD_FORWARDS_SUCCESS,
                payload: {
                    forwards: forwards,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const deleteForward =
    (auth, id, messageType) => async (dispatch) => {
        dispatch(beginApiCall());

        forwardApi
            .removeForward(auth, id)
            .then(() => {
                const obj = { MessageType: messageType, Protocol: "non" };
                dispatch({
                    type: types.REMOVE_DEVICE_FORWARD_SUCCESS,
                    payload: {
                        Id: id,
                        emptyForward: obj,
                    },
                });
                toast.success("Forward removed");
            })
            .catch((error) => {
                dispatch(apiCallError(error));
            });
    };

export const saveForward = (auth, forward) => async (dispatch) => {
    dispatch(beginApiCall());

    forwardApi
        .saveForward(auth, forward)
        .then((res) => {
            dispatch({
                type: types.SAVE_FORWARD_SUCCESS,
                payload: {
                    forward: res
                },
            });
            toast.success("Forward " + res.Name + " successfully created!");
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        });
}

export const saveIntegration = (auth, integration) => async (dispatch) => {
    dispatch(beginApiCall());

    forwardApi
        .saveIntegration(auth, integration)
        .then((res) => {
            dispatch({
                type: types.SAVE_FORWARD_SUCCESS,
                payload: {
                    forward: res
                },
            });
            toast.success("Forwards " + res.Name + " successfully created!");
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        });
}

export const updateForward = (auth, id, forward) => async (dispatch) => {
    dispatch(beginApiCall());

    forwardApi
        .updateForward(auth, id, forward)
        .then((res) => {
            dispatch({
                type: types.UPDATE_FORWARD_SUCCESS,
                payload: {
                    forward: res,
                },
            });
            toast.success("Forward " + res.Name + " succesfully updated!");
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}

export const removeForward = (auth, id) => async (dispatch) => {
    dispatch(beginApiCall());

    forwardApi
        .removeForward(auth, id)
        .then(() => {
            dispatch({
                type: types.REMOVE_FORWARD_SUCCESS,
                payload: {
                    forward: id,
                },
            });
            toast.warn("Forward succesfully removed!");
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}

export const loadSearchedForwards = (auth, searchTerm, filter, customer = null) => async (dispatch) => {
    dispatch(beginApiCall());

    forwardApi
        .getForwardsFromSearch(auth, searchTerm, filter, customer)
        .then((forwards) => {
            dispatch({
                type: types.LOAD_SEARCH_RESULT_FORWARD_SUCCESS,
                payload: {
                    forwards: forwards,
                }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        })
}