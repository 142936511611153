import * as types from "./actionsTypes";
import * as deviceTestAPI from "../api/deviceTestAPI";
import {beginApiCall, apiCallError} from "./apiStatusActions";
import {toast} from "react-toastify";

export const loadDeviceTests = (auth) => async (dispatch) => {
    dispatch(beginApiCall());

    deviceTestAPI
        .getDevicetests(auth)
        .then((tests) => {
            dispatch({
                type: types.LOAD_DEVICESTESTS_SUCCESS,
                payload: {
                    deviceTests: tests,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const getDeviceById = (auth, Id) => async (dispatch) => {
    dispatch(beginApiCall());
    dispatch(beginLoadingDeviceTest());

    deviceTestAPI
        .getDevicetestsById(auth, Id)
        .then((test) => {
            dispatch({
                type: types.LOAD_DEVICESTEST_SUCCESS,
                payload: {
                    deviceTest: test,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

const beginLoadingDeviceTest = () => async (dispatch) => {
    dispatch({
        type: "LOADING_DEVICETEST",
        payload: {
            isLoading: true,
        },
    });
};

export const saveForward = (auth, devicetest) => async (dispatch) => {
    dispatch(beginApiCall());

    deviceTestAPI
        .saveDevicetest(auth, devicetest)
        .then(() => {
            dispatch({
                type: types.SAVE_DEVICETEST_SUCCES,
                payload: {},
            });
            toast.success("DeviceTest Saved!");
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        });
}