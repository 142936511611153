import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { ErrorMessage, Field, Formik, useFormik } from "formik";
import updateForwardValidationSchema from "../Validation/updateForwardValidationSchema";


const UpdateForward = ({
    show,
    setShow,
    forward,
    customers,
    updateForwardHandler,
}) => {
    const handleClose = () => setShow(false);

    const initialValues = {
        Id: forward.Id,
        CustomerId: forward.CustomerId,
        Name: forward.Name,
        IP: forward.IP,
        Port: forward.Port,
        Username: forward.Username,
        Password: forward.Password,
        API_KEY: forward.API_KEY,
        Auth: forward.Auth,
        Status: forward.Status,
        MessageTypes: [forward.MessageTypes[0]?.MessageTypeId.toString()],
        Protocols: [forward.Protocols[0]?.ProtocolId.toString()],
    }

    const onSubmit = (values) => {
        setShow(false);
        updateForwardHandler(values);
    }

    const formik = useFormik({
        initialValues,
        updateForwardValidationSchema,
        onSubmit
    });

    return (
        <div>
            <Modal show={show} onHide={handleClose} centered className="text-center">
                <Modal.Header closeButton>
                    <Modal.Title>Update Forward</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={initialValues}
                    validationSchema={updateForwardValidationSchema}
                    onSubmit={onSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <Modal.Body className="text-left">
                                <Form.Label>Customer</Form.Label>
                                <Field
                                    as="select"
                                    name="CustomerId"
                                    value={values.CustomerId}
                                    className={`form-control ${touched.CustomerId && errors.CustomerId ? 'is-invalid' : ''}`}
                                >
                                    <option key="null" value="null">
                                        --
                                    </option>
                                    {customers.map((customer) => {
                                        return (
                                            <option key={customer.Id} value={customer.Id}>
                                                {customer.Name}
                                            </option>
                                        );
                                    })}
                                </Field>
                                <ErrorMessage component="div" name="CustomerId" className="invalid-feedback" />

                                <Form.Label>Name</Form.Label>
                                <Field
                                    type="text"
                                    name="Name"
                                    value={values.Name || ""}
                                    className={`form-control ${touched.Name && errors.Name ? 'is-invalid' : ''}`}
                                    placeholder="Forward Name"
                                />
                                <ErrorMessage component="div" name="Name" className="invalid-feedback" />

                                <Form.Label>IP(Address)</Form.Label>
                                <Field
                                    type="text"
                                    name="IP"
                                    value={values.IP || ""}
                                    className={`form-control ${touched.IP && errors.IP ? 'is-invalid' : ''}`}
                                    placeholder="Forward IP"
                                />
                                <ErrorMessage component="div" name="IP" className="invalid-feedback" />

                                <Form.Label>Port</Form.Label>
                                <Field
                                    type="text"
                                    name="Port"
                                    value={values.Port || ""}
                                    className={`form-control ${touched.Port && errors.Port ? 'is-invalid' : ''}`}
                                    placeholder="Forward Port"
                                />
                                <ErrorMessage component="div" name="Port" className="invalid-feedback" />
                                <Row>
                                    <Col>
                                        <Form.Label>Username</Form.Label>
                                        <Field
                                            type="text"
                                            name="Username"
                                            value={values.Username || ""}
                                            className={`form-control ${touched.Username && errors.Username ? 'is-invalid' : ''}`}
                                            placeholder="Forward Username"
                                        />
                                        <ErrorMessage component="div" name="Username" className="invalid-feedback" />
                                    </Col>

                                    <Col>
                                        <Form.Label>Password</Form.Label>
                                        <Field
                                            type="text"
                                            name="Password"
                                            value={values.Password || ""}
                                            className={`form-control ${touched.Password && errors.Password ? 'is-invalid' : ''}`}
                                            placeholder="Forward Password"
                                        />
                                        <ErrorMessage component="div" name="Password" className="invalid-feedback" />
                                    </Col>
                                </Row>
                                <Form.Label>API_KEY</Form.Label>
                                <Field
                                    type="text"
                                    name="API_KEY"
                                    value={values.API_KEY || ""}
                                    className={`form-control ${touched.API_KEY && errors.API_KEY ? 'is-invalid' : ''}`}
                                    placeholder="Forward API Key"
                                />
                                <ErrorMessage component="div" name="API_KEY" className="invalid-feedback" />

                                <Form.Label>Authorization</Form.Label>
                                <Field
                                    type="text"
                                    name="Auth"
                                    value={values.Auth || ""}
                                    className={`form-control ${touched.Auth && errors.Auth ? 'is-invalid' : ''}`}
                                    placeholder="Forward Authorization"
                                />
                                <ErrorMessage component="div" name="Auth" className="invalid-feedback" />

                                <Form.Label>Status</Form.Label>
                                <Field
                                    as="select"
                                    name="Status"
                                    value={values.Status}
                                    className={`form-control ${touched.Status && errors.Status ? 'is-invalid' : ''}`}
                                >
                                    <option key="0" value="0">
                                        Server Down
                                    </option>
                                    <option key="1" value="1">
                                        Server Up & Running
                                    </option>
                                </Field>
                                <ErrorMessage component="div" name="Status" className="invalid-feedback" />

                                {/* <Form.Row>
                                    <Col>
                                        <Form.Label>Message Type</Form.Label>
                                        <Field
                                            as="select"
                                            name="MessageTypes"
                                            value={values.MessageTypes[0]}
                                            className={`form-control ${touched.MessageTypes && errors.MessageTypes ? 'is-invalid' : ''}`}
                                            required
                                            onChange={(e) => {
                                                if (e.target.value === "null") {
                                                    setFieldValue("MessageTypes", []);
                                                    return;
                                                }

                                                setFieldValue("MessageTypes", [e.target.value]);
                                            }}
                                        >
                                            <option key="null" value="null">
                                                --
                                            </option>
                                            {messageTypes.map((messagetype) => {
                                                return (
                                                    <option key={messagetype.MessageTypeId}
                                                        value={messagetype.MessageTypeId}>
                                                        {messagetype.MessageTypeId} - {messagetype.MessageType}
                                                    </option>
                                                );
                                            })}
                                        </Field>
                                        <ErrorMessage component="div" name="MessageTypes" className="invalid-feedback" />
                                    </Col>
                                    <Col>
                                        <Form.Label>Protocol</Form.Label>
                                        <Field
                                            as="select"
                                            name="Protocols"
                                            value={values.Protocols[0]}
                                            className={`form-control ${touched.Protocols && errors.Protocols ? 'is-invalid' : ''}`}
                                            required
                                            onChange={(e) => {
                                                if (e.target.value === "null") {
                                                    setFieldValue("Protocols", []);
                                                    return;
                                                }

                                                setFieldValue("Protocols", [e.target.value]);
                                            }}
                                        >
                                            <option key="null" value="null">
                                                --
                                            </option>
                                            {protocols.map((protocol) => {
                                                return (
                                                    <option key={protocol.ProtocolId}
                                                        value={protocol.ProtocolId}>
                                                        {protocol.ProtocolId} - {protocol.Protocol}
                                                    </option>
                                                );
                                            })}
                                        </Field>
                                        <ErrorMessage component="div" name="Protocols" className="invalid-feedback" />
                                    </Col>
                                </Form.Row> */}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    disabled={!formik.isValid}
                                >
                                    Update Forward
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    );
};

UpdateForward.propTypes = {
    auth: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    customers: PropTypes.array.isRequired,
    updateForwardHandler: PropTypes.func.isRequired,
    forward: PropTypes.object,
    protocols: PropTypes.array,
    messageTypes: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateForward);
