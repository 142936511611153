import * as types from "./actionsTypes";
import * as deviceForwardApi from "../api/deviceForwardApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";

export const getForwardsByDevice = (auth, id) => async (dispatch) => {
  dispatch(beginLoadingDevice());

  deviceForwardApi
    .getForwardsByDevice(auth, id)
    .then((deviceForwards) => {
      const defaultForwards = [
        { MessageType: "TD", Protocol: "non" },
        { MessageType: "RT", Protocol: "non" },
        { MessageType: "TC", Protocol: "non" },
        { MessageType: "TL", Protocol: "non" },
      ];

      defaultForwards.forEach((defaultForward, index, array) => {
        var forward = deviceForwards.find((deviceForward) => {
          return defaultForward.MessageType == deviceForward.MessageType;
        });
        if (forward != undefined) {
          array[index] = forward;
        }
      });
      dispatch({
        type: types.LOAD_DEVICE_FORWARDS_SUCCESS,
        payload: {
          forwards: defaultForwards,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const saveForwardDevice = (auth, request) => async (dispatch) => {
  dispatch(beginApiCall());

  deviceForwardApi
    .saveForwardDevice(auth, request)
    .then((forward) => {
      dispatch({
        type: types.SAVE_DEVICE_FORWARD_SUCCESS,
        payload: {
          forwards: forward,
        },
      });
      toast.success("Forward saved");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const updateForwardDevice = (auth, request) => async (dispatch) => {
  dispatch(beginApiCall());

  deviceForwardApi
    .updateForwardDevice(auth, request)
    .then((forward) => {
      dispatch({
        type: types.UPDATE_DEVICE_FORWARD_SUCCESS,
        payload: {
          forwards: forward,
        },
      });
      toast.success("Forward updated");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const removeForwardDevice =
  (auth, id, messageType) => async (dispatch) => {
    dispatch(beginApiCall());

    deviceForwardApi
      .removeForwardDevice(auth, id)
      .then(() => {
        const obj = { MessageType: messageType, Protocol: "non" };
        dispatch({
          type: types.REMOVE_DEVICE_FORWARD_SUCCESS,
          payload: {
            Id: id,
            emptyForward: obj,
          },
        });
        toast.success("Forward removed");
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };

const beginLoadingDevice = () => async (dispatch) => {
  dispatch(beginApiCall());
  
  dispatch({
    type: "LOADING_DEVICE",
    payload: {
      isLoading: true,
    },
  });
};
