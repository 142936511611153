import * as types from "./actionsTypes";
import * as endpointApi from "../api/endpointApi"
import { beginApiCall, apiCallError } from "./apiStatusActions"
import { toast } from "react-toastify";

export const fetchEndpoints = (auth) => async (dispatch) => {
    dispatch(beginApiCall())

    endpointApi.getEndpoints(auth)
        .then((endpoints) => {
            dispatch({
                type: types.LOAD_ENDPOINTS_SUCCESS,
                payload: { endpoints: endpoints }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        })
}

export const fetchEndpointsByCustomer = (auth, customer) => async (dispatch) => {
    dispatch(beginApiCall())

    endpointApi.getEndpointsByCustomer(auth, customer)
        .then((endpoints) => {
            dispatch({
                type: types.LOAD_ENDPOINTS_SUCCESS,
                payload: { endpoints: endpoints }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        })
}


export const loadSearchedEndpoints = (auth, searchTerm, filter, customer = null) => async (dispatch) => {
    dispatch(beginApiCall())

    endpointApi
        .getEndpointsFromSearch(auth, searchTerm, filter, customer)
        .then((endpoints) => {
            dispatch({
                type: types.LOAD_SEARCH_RESULT_ENDPOINTS_SUCCESS,
                payload: {
                    endpoints: endpoints
                }
            })
        })
}

export const saveEndpoint = (auth, endpoint) => async (dispatch) => {
    dispatch(beginApiCall())

    endpointApi.saveEndpoint(auth, endpoint)
        .then((endpoint) => {
            dispatch({
                type: types.SAVE_ENDPOINT_SUCCESS,
                payload: { endpoint: endpoint }
            })
            toast.success("Endpoint " + endpoint.Name + " saved succesfully")
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        })
}

export const updateEndpoint = (auth, endpoint) => async (dispatch) => {
    dispatch(beginApiCall())

    endpointApi.updateEndpoint(auth, endpoint)
        .then((endpoint) => {
            dispatch({
                type: types.UPDATE_ENDPOINT_SUCCESS,
                payload: { endpoint: endpoint }
            })
            toast.success("Endpoint " + endpoint.Name + " updated succesfully")
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        })
}

export const deleteEndpoint = (auth, endpoint) => async (dispatch) => {
    dispatch(beginApiCall())
    endpointApi.deleteEndpoint(auth, endpoint.Id)
        .then(() => {
            dispatch({
                type: types.DELETE_ENDPOINT_SUCCESS,
                payload: { endpoint: endpoint }
            })
            toast.success("Endpoint " + endpoint.Name + " deleted")
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        })
}