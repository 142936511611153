import React from "react";
import { connect } from "react-redux";
import { Button, Col, Row, Form, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { ErrorMessage, Field, Formik, useFormik } from "formik";
import validationSchema from "../Validation/ValidationSchema";

const CreateForward = ({
    auth,
    show,
    setShow,
    customers,
    protocols,
    messageTypes,
    saveForward,
}) => {
    const handleShow = () => setShow(true);

    const handleClose = () => {
        setShow(false);
    };

    const saveForwardHandler = (values) => {
        setShow(false);
        saveForward(values);
    };

    const initialValues = {
        CustomerId: "",
        Name: "",
        IP: "",
        Port: 0,
        Username: "",
        Password: "",
        API_KEY: "",
        Auth: "",
        Status: 1,
        MessageTypes: "",
        Protocols: "",
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: saveForwardHandler,
    });

    const isViewer = auth.user.Role === "Viewer";

    return (
        <div>
            {!isViewer && (
                <Button className="create-object-button ml-1" onClick={handleShow}>
                    New Forward
                </Button>
            )}
            <Modal show={show} onHide={handleClose} centered className="text-center">
                <Modal.Header closeButton>
                    <Modal.Title>Add Forward</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={saveForwardHandler}
                >
                    {({ errors, touched, handleSubmit, setFieldValue }) => (
                        <Form onSubmit={handleSubmit}>
                            <Modal.Body className="text-left">
                                <Form.Label>Customer</Form.Label>
                                <Field
                                    as="select"
                                    name="CustomerId"
                                    className={`form-control ${errors.CustomerId && touched.CustomerId ? "is-invalid" : ""}`}
                                    required
                                >
                                    <option key="null" value="">
                                        --
                                    </option>
                                    {customers.map((customer) => (
                                        <option key={customer.Id} value={customer.Id}>
                                            {customer.Name}
                                        </option>
                                    ))}
                                </Field>
                                <ErrorMessage name="CustomerId" component="div" className="invalid-feedback" />

                                <Form.Label>Name</Form.Label>
                                <Field
                                    type="text"
                                    name="Name"
                                    placeholder="Forward Name"
                                    className={`form-control ${errors.Name && touched.Name ? "is-invalid" : ""}`}
                                    required
                                />
                                <ErrorMessage name="Name" component="div" className="invalid-feedback" />

                                <Form.Label>IP (Address)</Form.Label>
                                <Field
                                    type="text"
                                    name="IP"
                                    placeholder="IP Address"
                                    className={`form-control ${errors.IP && touched.IP ? "is-invalid" : ""}`}
                                    required
                                />
                                <ErrorMessage name="IP" component="div" className="invalid-feedback" />

                                <Form.Label>Port</Form.Label>
                                <Field
                                    type="text"
                                    name="Port"
                                    placeholder="Port"
                                    className={`form-control ${errors.Port && touched.Port ? "is-invalid" : ""}`}
                                    required
                                />
                                <ErrorMessage name="Port" component="div" className="invalid-feedback" />

                                <Row>
                                    <Col>
                                        <Form.Label>Username</Form.Label>
                                        <Field
                                            type="text"
                                            name="Username"
                                            placeholder="Username"
                                            className={`form-control ${errors.Username && touched.Username ? "is-invalid" : ""}`}
                                            required
                                        />
                                        <ErrorMessage name="Username" component="div" className="invalid-feedback" />
                                    </Col>
                                    <Col>
                                        <Form.Label>Password</Form.Label>
                                        <Field
                                            type="text"
                                            name="Password"
                                            placeholder="Password"
                                            className={`form-control ${errors.Password && touched.Password ? "is-invalid" : ""}`}
                                            required
                                        />
                                        <ErrorMessage name="Password" component="div" className="invalid-feedback" />
                                    </Col>
                                </Row>

                                <Form.Label>API_KEY</Form.Label>
                                <Field
                                    type="text"
                                    name="API_KEY"
                                    placeholder="Forward API Key"
                                    className={`form-control ${errors.API_KEY && touched.API_KEY ? "is-invalid" : ""}`}
                                    required
                                />
                                <ErrorMessage name="API_KEY" component="div" className="invalid-feedback" />

                                <Form.Label>Authorization</Form.Label>
                                <Field
                                    type="text"
                                    name="Auth"
                                    placeholder="Forward Authorization"
                                    className={`form-control ${errors.Auth && touched.Auth ? "is-invalid" : ""}`}
                                    required
                                />
                                <ErrorMessage name="Auth" component="div" className="invalid-feedback" />

                                <Form.Label>Status</Form.Label>
                                <Field
                                    as="select"
                                    name="Status"
                                    className={`form-control ${errors.Status && touched.Status ? "is-invalid" : ""}`}
                                    required
                                >
                                    <option key="0" value="0">
                                        Server Down
                                    </option>
                                    <option key="1" value="1">
                                        Server Up & Running
                                    </option>
                                </Field>
                                <ErrorMessage name="Status" component="div" className="invalid-feedback" />

                                <Row>
                                    <Col>
                                        <Form.Label>Message Type</Form.Label>
                                        <Field
                                            as="select"
                                            name="MessageTypes"
                                            className={`form-control ${errors.MessageTypes && touched.MessageTypes ? "is-invalid" : ""}`}
                                            required
                                            onChange={(e) => {
                                                if (e.target.value === "null") {
                                                    setFieldValue("MessageTypes", "");
                                                    return;
                                                }

                                                setFieldValue("MessageTypes", e.target.value);
                                            }}
                                        >
                                            <option key="null" value="">
                                                --
                                            </option>
                                            {messageTypes.map((messagetype) => (
                                                <option
                                                    key={messagetype.MessageTypeId}
                                                    value={messagetype.MessageTypeId}
                                                >
                                                    {messagetype.MessageTypeId} - {messagetype.MessageType}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage name="MessageTypes" component="div" className="invalid-feedback" />
                                    </Col>
                                    <Col>
                                        <Form.Label>Protocols</Form.Label>
                                        <Field
                                            as="select"
                                            name="Protocols"
                                            className={`form-control ${errors.Protocols && touched.Protocols ? "is-invalid" : ""}`}
                                            required
                                            onChange={(e) => {
                                                if (e.target.value === "null") {
                                                    setFieldValue("Protocols", "");
                                                    return;
                                                }

                                                setFieldValue("Protocols", e.target.value);
                                            }}
                                        >
                                            <option key="null" value="">
                                                --
                                            </option>
                                            {protocols.map((protocol) => (
                                                <option
                                                    key={protocol.ProtocolId}
                                                    value={protocol.ProtocolId}
                                                >
                                                    {protocol.ProtocolId} - {protocol.Protocol}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage name="Protocols" component="div" className="invalid-feedback" />
                                        <small className="text-black-50">
                                            Warning! Cello only works with the RT Message Type!
                                        </small>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" type="submit" disabled={!formik.isValid}>
                                    Save Forward
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    );
};

CreateForward.propTypes = {
    auth: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    customers: PropTypes.array.isRequired,
    protocols: PropTypes.array.isRequired,
    messageTypes: PropTypes.array.isRequired,
    saveForward: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        customers: state.customers,
        protocols: state.protocols,
        messageTypes: state.messageTypes,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateForward);
