import React from "react"
import EndpointValidationSchema from "../validation/EndpointValidationSchema"
import { ErrorMessage, Field, Formik, useFormik } from "formik";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Form, Modal } from "react-bootstrap";

const CreateEndpoint = ({ auth, show, setShow, customers, saveEndpoint }) => {
    const handleShow = () => { setShow(true) }
    const handleClose = () => { setShow(false) }

    const saveEndpointHandler = (values) => {
        setShow(false)
        saveEndpoint(values)
    }

    const initialValues = {
        CustomerId: null,
        Name: "",
        URL: "",
        Protocol: 1
    }

    const formik = useFormik({
        initialValues,
        EndpointValidationSchema,
        onSubmit: saveEndpointHandler
    })

    const isViewer = auth.user.Role === "Viewer"
    return (
        <div>
            {!isViewer && (
                <Button className="create-object-button m1-1" onClick={handleShow}>
                    New Endpoint
                </Button>
            )}
            <Modal show={show} onHide={handleClose} centered className="text-center">
                <Modal.Header closeButton>
                    <Modal.Title>Create Endpoint</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={initialValues}
                    validationSchema={EndpointValidationSchema}
                    onSubmit={saveEndpointHandler}
                >
                    {({ errors, touched, handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            <Modal.Body className="text-left">
                                <Form.Label>Customer</Form.Label>
                                <Field
                                    as="select"
                                    name="CustomerId"
                                    className={`form-control ${errors.CustomerId && touched.CustomerId ? "is-invalid" : ""}`}
                                    required
                                >
                                    <option key="null" value="">
                                        --
                                    </option>
                                    {customers.map((customer) => (
                                        <option key={customer.Id} value={customer.Id}>
                                            {customer.Name}
                                        </option>
                                    ))}
                                </Field>
                                <ErrorMessage name="CustomerId" component="div" className="invalid-feedback" />

                                <Form.Label>Name</Form.Label>
                                <Field
                                    type="text"
                                    name="Name"
                                    placeholder="Endpoint Name"
                                    className={`form-control ${errors.Name && touched.Name ? "is-invalid" : ""}`}
                                    required
                                />
                                <ErrorMessage name="Name" component="div" className="invalid-feedback" />

                                <Form.Label>URL</Form.Label>
                                <Field
                                    type="text"
                                    name="URL"
                                    placeholder="Endpoint URL"
                                    className={`form-control ${errors.URL && touched.URL ? "is-invalid" : ""}`}
                                    required
                                />
                                <ErrorMessage name="URL" component="div" className="invalid-feedback" />

                                <Form.Label>Protocol</Form.Label>
                                <Field
                                    as="select"
                                    name="Protocol"
                                    className={`form-control ${errors.Protocol && touched.Protocol ? "is-invalid" : ""}`}
                                    required
                                >
                                    <option key={1} value={1}>
                                        HTTP
                                    </option>
                                    <option key={3} value={3}>
                                        TCP
                                    </option>
                                </Field>
                                <ErrorMessage name="Protocol" component="div" className="invalid-feedback" />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    disabled={!formik.isValid}
                                >
                                    Save Endpoint
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    )
}

CreateEndpoint.propTypes = {
    auth: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    customers: PropTypes.array.isRequired,
    saveEndpoint: PropTypes.func.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        customers: state.customers
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEndpoint)