import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/settingsProfiles/"

export const getSettingsProfiles = (auth) => {
    return fetch(baseUrl, {
        headers: {
            Authorization: auth.accessToken,
        },
    })
        .then(handleResponse)
        .catch(handleError);
}

export const saveSettingsProfile = (auth, profile) => {
    profile = {
        ...profile,
        message0Protocol: profile.message0.protocol,
        message1Protocol: profile.message1.protocol,
        message2Protocol: profile.message2.protocol,
    }
    return fetch(baseUrl, {
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: auth.accessToken,
        },
        body: JSON.stringify(profile)
    })
        .then(handleResponse)
        .catch(handleError)
}

export const removeSettingsProfile = (auth, profileId) => {
    return fetch(baseUrl + profileId, {
        method: "DELETE",
        headers: {
            Authorization: auth.accessToken,
        }
    })
        .then(handleResponse)
        .catch(handleError)
}

export const updateSettingsProfile = (auth, profile) => {
    profile = {
        ...profile,
        message0Protocol: profile.message0.protocol,
        message1Protocol: profile.message1.protocol,
        message2Protocol: profile.message2.protocol,
    }
    return fetch(baseUrl + profile.Id, {
        method: "POST",
        headers: {
            Authorization: auth.accessToken,
            "content-type": "application/json",
        },
        body: JSON.stringify(profile)
    })
        .then(handleResponse)
        .catch(handleError)
}

export const fetchSettingsProfilesByCustomer = (auth, customer) => {
    return fetch(baseUrl + "customer/" + customer, {
        method: "GET",
        headers: {
            Authorization: auth.accessToken,
        }
    })
        .then(handleResponse)
        .catch(handleError)
}

export const fetchSettingsProfilesByName = (auth, name, customer) => {
    return customer === "null"
        ? fetch(baseUrl + "name/" + name, {
            method: "GET",
            headers: {
                Authorization: auth.accessToken
            }
        })
            .then(handleResponse)
            .catch(handleError)
        : fetch(baseUrl + "name/" + name + "/" + customer, {
            method: "GET",
            headers: {
                Authorization: auth.accessToken
            }
        })
            .then(handleResponse)
            .catch(handleError)
}