import * as types from "../actions/actionsTypes"
import initialState from "./initialState"

const settingsProfileReducer = (
    state = initialState.settingsProfiles,
    action
) => {
    switch (action.type) {
        case types.LOAD_SETTINGS_PROFILES_SUCCESS:
            {
                action.payload.settingsProfiles.forEach(e => {
                    e.message0 = { ...e.message0, index: 0, protocol: e.message0Protocol }
                    e.message1 = { ...e.message1, index: 1, protocol: e.message1Protocol }
                    e.message2 = { ...e.message2, index: 2, protocol: e.message2Protocol }
                    delete e.message0Protocol
                    delete e.message1Protocol
                    delete e.message2Protocol
                });
                return action.payload.settingsProfiles
            }
        case types.SAVE_SETTINGS_PROFILE_SUCCESS:
            {
                var profile = {
                    ...action.payload.settingsProfile,
                    message0: {
                        ...action.payload.settingsProfile.message0,
                        index: 0,
                        protocol: action.payload.settingsProfile.message0Protocol
                    },
                    message1: {
                        ...action.payload.settingsProfile.message1,
                        index: 1,
                        protocol: action.payload.settingsProfile.message1Protocol
                    },
                    message2: {
                        ...action.payload.settingsProfile.message2,
                        index: 2,
                        protocol: action.payload.settingsProfile.message2Protocol
                    }
                }
                delete profile.message0Protocol
                delete profile.message1Protocol
                delete profile.message2Protocol
                return [
                    ...state.filter((profile) => profile.Id != action.payload.settingsProfile.Id),
                    profile
                ]
            }
        case types.DELETE_SETTINGS_PROFILE_SUCCESS:
            return state.filter(settingsProfile => settingsProfile.Id !== action.payload.Id);
        case types.UPDATE_SETTINGS_PROFILE_SUCCESS:
            var prf = {
                ...action.payload.settingsProfile,
                message0: {
                    ...action.payload.settingsProfile.message0,
                    index: 0,
                    protocol: action.payload.settingsProfile.message0Protocol
                },
                message1: {
                    ...action.payload.settingsProfile.message1,
                    index: 1,
                    protocol: action.payload.settingsProfile.message1Protocol
                },
                message2: {
                    ...action.payload.settingsProfile.message2,
                    index: 2,
                    protocol: action.payload.settingsProfile.message2Protocol
                }
            }
            delete prf.message0Protocol
            delete prf.message1Protocol
            delete prf.message2Protocol
            return [
                ...state.filter((profile) => profile.Id != action.payload.settingsProfile.Id),
                prf
            ]
        default:
            return state;
    }
};

export default settingsProfileReducer;