import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/devices/forwards/";

export function getForwardsByDevice(auth, imei) {
  return fetch(baseUrl + imei, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveForwardDevice(auth, request) {
  return fetch(baseUrl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(request),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function updateForwardDevice(auth, request) {
  return fetch(baseUrl, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(request),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function removeForwardDevice(auth, id) {
  return fetch(baseUrl + id, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
