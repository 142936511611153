const baseUrl = process.env.API_URL + "/api/forwards/";
import {handleResponse, handleError} from "./apiUtils";

export function getForwardsCount(auth) {
    return fetch(baseUrl + "all/", { headers: { Authorization: auth.accessToken } })
        .then(handleResponse)
        .catch(handleError);
}

export function getForwards(auth) {
    return fetch(baseUrl, {
        headers: {
            Authorization: auth.accessToken,
        },
    })
        .then(handleResponse)
        .catch(handleError);
}

export function getProtocols(auth) {
    return fetch(baseUrl + "protocols", {
        headers: {
            Authorization: auth.accessToken,
        },
    })
        .then(handleResponse)
        .catch(handleError);
}

export function getMessageTypes(auth) {
    return fetch(baseUrl + "messagetypes", {
        headers: {
            Authorization: auth.accessToken,
        },
    })
        .then(handleResponse)
        .catch(handleError);
}

export function getForwardById(auth, id) {
    return fetch(baseUrl + "id/" + id, {
        headers: {
            Authorization: auth.accessToken,
        },
    })
        .then(handleResponse)
        .catch(handleError);
}

export function getForwardsByCustomer(auth, id) {
    return fetch(baseUrl + id, {
        headers: {
            Authorization: auth.accessToken,
        },
    })
        .then(handleResponse)
        .catch(handleError);
}

export const saveForward = (auth, forward) => {
    return fetch(baseUrl, {
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: auth.accessToken,
        },
        body: JSON.stringify(forward),
    })
        .then(handleResponse)
        .catch(handleError);
};


export const saveIntegration = (auth, integration) => {
    return fetch(baseUrl, {
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: auth.accessToken,
        },
        body: JSON.stringify(integration),
    })
        .then(handleResponse)
        .catch(handleError);
};

export const updateForward = (auth, id, forward) => {
    return fetch(baseUrl + id, {
        method: "PUT",
        headers: {
            "content-type": "application/json",
            Authorization: auth.accessToken,
        },
        body: JSON.stringify(forward),
    })
        .then(handleResponse)
        .catch(handleError);
};

export const removeForward = (auth, id) => {
    return fetch(baseUrl + id, {
        method: "DELETE",
        headers: {
            Authorization: auth.accessToken,
            "Content-Type": "application/json",
        },
    })
        .then(handleResponse)
        .catch(handleError);
};


export function getForwardsFromSearch(auth, searchTerm, filter, customer = null) {
    return customer === "null"
        ? fetch(`${baseUrl}search/${searchTerm}/${filter}`, {headers: {Authorization: auth.accessToken}})
            .then(handleResponse)
            .catch(handleError)
        : fetch(`${baseUrl}search/${searchTerm}/${filter}/${customer}`, {headers: {Authorization: auth.accessToken}})
            .then(handleResponse)
            .catch(handleError)
}