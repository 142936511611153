import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Table } from "react-bootstrap";
import MessageURLControlRow from "../../deviceDetails/messages/components/MessageURLControlRow";
import MessageOverViewRow from "../../deviceDetails/messages/components/MessageOverviewRow";
import ChangeMessageModal from "../../deviceDetails/messages/components/ChangeMessageModal";

const SettingsProfileMessagesModal = ({
    settingsProfile,
    setSettingsProfile,
    nextPage,
    previousPage,
    endpoints
}) => {
    const [message, setMessage] = useState({});
    const [show, setShow] = useState(false)
    const showModalHandler = (message) => {
        setMessage(message)
        setShow(true);
    }

    const clearedValues = {
        TIME: 0,
        SOT: 0,
        EOT: 0,
        CHG: 0,
        POR: 0,
        WAKE: 0,
        STB: 0,
        MOV: 0,
        LOWBV: 0,
        PZ: 0,
        DWL: 0,
        LOG: 0,
        DS: 0,
        DIAG: 0,
        TL: 0,
        REQ: 0,
    }

    const saveMessageHandler = () => {
        switch (message.index) {
            case 0:
                setSettingsProfile({
                    ...settingsProfile,
                    message0: { ...message },
                    endpoint0ID: message.endpointID
                })
                break
            case 1:
                setSettingsProfile({
                    ...settingsProfile,
                    message1: { ...message },
                    endpoint1ID: message.endpointID
                })
                break
            case 2:
                setSettingsProfile({
                    ...settingsProfile,
                    message2: { ...message },
                    endpoint2ID: message.endpointID
                })
                break
        }
    }

    const clearMessage = (msg) => {
        switch (msg.index) {
            case 0:
                setSettingsProfile({
                    ...settingsProfile,
                    message0: { ...clearedValues, URL: msg.URL, index: msg.index, protocol: msg.protocol, endpointID: msg.endpointID }
                })
                break;
            case 1:
                setSettingsProfile({
                    ...settingsProfile,
                    message1: { ...clearedValues, URL: msg.URL, index: msg.index, protocol: msg.protocol, endpointID: msg.endpointID }
                })
                break;
            case 2:
                setSettingsProfile({
                    ...settingsProfile,
                    message2: { ...clearedValues, URL: msg.URL, index: msg.index, protocol: msg.protocol, endpointID: msg.endpointID }
                })
                break;
        }
    }

    const disableOptions = () => { return false }
    // console.log(settingsProfile)

    return (
        <>
            <Modal.Body>
                <MessageURLControlRow
                    index={0}
                    message={{ ...settingsProfile.message0, index: 0, endpointID: settingsProfile.endpoint0ID  }}
                    setMessage={setMessage}
                    isViewer={false}
                    disableOptions={disableOptions}
                    saveMessageHandler={saveMessageHandler}
                    endpoints={endpoints}
                />
                <br />
                <MessageURLControlRow
                    index={1}
                    message={{ ...settingsProfile.message1, index: 1, endpointID: settingsProfile.endpoint1ID }}
                    setMessage={setMessage}
                    isViewer={false}
                    disableOptions={disableOptions}
                    saveMessageHandler={saveMessageHandler}
                    endpoints={endpoints}
                />
                <br />
                <MessageURLControlRow
                    index={2}
                    message={{ ...settingsProfile.message2, index: 2, endpointID: settingsProfile.endpoint2ID }}
                    setMessage={setMessage}
                    isViewer={false}
                    disableOptions={disableOptions}
                    saveMessageHandler={saveMessageHandler}
                    endpoints={endpoints}
                />
                <hr></hr>
                <Table striped hover responsive>
                    <thead>
                        <tr>
                            <th>Message</th>
                            <th>Time</th>
                            <th>TripStart</th>
                            <th>TripEnd</th>
                            <th>Charge</th>
                            <th>Power</th>
                            <th>Wake</th>
                            <th>Standby</th>
                            <th>Move</th>
                            <th>LowBV</th>
                            <th>IO</th>
                            <th>Unauth</th>
                            <th>Login</th>
                            <th>DriveStyle</th>
                            <th>Diag</th>
                            <th>TachoLive</th>
                            <th>Request</th>
                        </tr>
                    </thead>
                    <tbody>
                        <MessageOverViewRow
                            message={{ ...settingsProfile.message0, index: 0 }}
                            showModalHandler={showModalHandler}
                            clearMessage={clearMessage}
                        />
                        <MessageOverViewRow
                            message={{ ...settingsProfile.message1, index: 1 }}
                            showModalHandler={showModalHandler}
                            clearMessage={clearMessage}
                        />
                        <MessageOverViewRow
                            message={{ ...settingsProfile.message2, index: 2 }}
                            showModalHandler={showModalHandler}
                            clearMessage={clearMessage}
                        />
                    </tbody>
                </Table>
                {message && (
                    <ChangeMessageModal
                        message={message}
                        saveMessageHandler={saveMessageHandler}
                        setMessage={setMessage}
                        name={"Message configuration"}
                        show={show}
                        setShow={setShow}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={previousPage}>Back</Button>
                <Button onClick={nextPage}>Next</Button>
            </Modal.Footer>
        </>
    )
}

SettingsProfileMessagesModal.propTypes = {
    settingsProfile: PropTypes.object.isRequired,
    setSettingsProfile: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    endpoints: PropTypes.array.isRequired
}

export default SettingsProfileMessagesModal