import * as types from "./actionsTypes";
import * as deviceApi from "../api/deviceApi";
import {beginApiCall, apiCallError} from "./apiStatusActions";

export const loadDeviceChunk = (auth) => async (dispatch) => {
    dispatch(beginApiCall());

    deviceApi
        .getDeviceBatch(auth)
        .then((devices) => {
            
            dispatch({
                type: types.LOAD_FIRST_BATCH_DEVICES_SUCCESS,
                payload: {
                    devices: devices,
                },
            });

            dispatch({
                type: types.CHECK_END_OF_LIST,
                payload: {
                    messages: devices,
                }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const loadDeviceChunkFromId = (auth, id) => async (dispatch) => {
    dispatch(beginApiCall());

    deviceApi
        .getDeviceBatchFromId(auth, id)
        .then((devices) => {
            dispatch({
                type: types.LOAD_BATCH_DEVICES_SUCCESS,
                payload: {
                    devices: devices,
                },
            })

            dispatch({
                type: types.CHECK_END_OF_LIST,
                payload: {
                    messages: devices,
                }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const loadDevices = (auth) => async (dispatch) => {
    dispatch(beginApiCall());

    deviceApi
        .getDevices(auth)
        .then((devices) => {
            dispatch({
                type: types.LOAD_DEVICES_SUCCESS,
                payload: {
                    devices: devices,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const getDeviceById = (auth, Id) => async (dispatch) => {
    dispatch(beginApiCall());
    dispatch(beginLoadingDevice());

    deviceApi
        .getDeviceById(auth, Id)
        .then((device) => {
            dispatch({
                type: types.LOAD_DEVICE_DETAILS_SUCCESS,
                payload: {
                    device: device,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const getRawmessagesChunkByDevice = (auth, imei) => async (dispatch) => {
    dispatch(beginApiCall());

    deviceApi
        .getRawmessagesByDevice(auth, imei)
        .then((messages) => {
            dispatch({
                type: types.LOAD_FIRST_BATCH_DEVICE_RAW_MESSAGES_SUCCESS,
                payload: {
                    rawMessages: messages,
                },
            });
            dispatch({
                type: types.CHECK_END_OF_LIST,
                payload: {
                    messages: messages,
                }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const getRawmessagesChunkByDeviceById = (auth, imei, id) => async (dispatch) => {
    dispatch(beginApiCall());

    deviceApi
        .getRawmessagesByDeviceById(auth, imei, id)
        .then((messages) => {
            dispatch({
                type: types.LOAD_BATCH_DEVICE_RAW_MESSAGES_SUCCESS,
                payload: {
                    rawMessages: messages,
                },
            });
            dispatch({
                type: types.CHECK_END_OF_LIST,
                payload: {
                    messages: messages,
                }
            })
        })
        .catch((error) => {
            // console.log(error)
            dispatch(apiCallError(error));
        });
}

export const getLastRawmessagesByDevice = (auth, imei) => async (dispatch) => {
    dispatch(beginApiCall());
    dispatch(beginLoadingDevice());

    deviceApi
        .getLastRawmessagesByDevice(auth, imei)
        .then((messages) => {
            dispatch({
                type: types.LOAD_DEVICE_RAW_MESSAGES_SUCCESS,
                payload: {
                    rawMessages: messages,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const dispatchDeviceRawMsgToState = (rawMsg) => async (dispatch) => {
    dispatch({
        type: types.NEW_RAWMESSAGE_DISPATCHED_TO_STATE,
        payload: {
            deviceRawMessage: rawMsg,
        },
    })
}

export const getDeviceState = (auth, imei) => async (dispatch) => {
    dispatch(beginApiCall());
    dispatch(beginLoadingDevice());

    deviceApi
        .getDeviceState(auth, imei)
        .then((state) => {
            dispatch({
                type: types.LOAD_DEVICE_STATE_SUCCESS,
                payload: {
                    deviceState: state,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

const beginLoadingDevice = () => async (dispatch) => {
    dispatch({
        type: "LOADING_DEVICE",
        payload: {
            isLoading: true,
        },
    });
};

export const loadDevicesByCustomer = (auth, customer) => async (dispatch) => {
    dispatch(beginApiCall());

    deviceApi
        .getDevicesByCustomer(auth, customer)
        .then((devices) => {
            dispatch({
                type: types.LOAD_DEVICES_SUCCESS,
                payload: {
                    devices: devices
                }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        });
}

export const loadSearchedDevices = (auth, searchTerm, filter, customer = null) => async (dispatch) => {
    dispatch(beginApiCall());

    deviceApi
        .getDevicesFromSearch(auth, searchTerm, filter, customer)
        .then((devices) => {
            dispatch({
                type: types.LOAD_SEARCH_RESULT_DEVICES_SUCCESS,
                payload: {
                    devices: devices,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}

export const saveDeviceNote = (auth, imei, note) => async (dispatch) => {
    dispatch(beginApiCall());
    deviceApi
        .saveDeviceNote(auth, {imei, note})
        .then((devices) => {
            dispatch({
                type: types.SAVE_DEVICE_NOTE_SUCCESS,
                payload: {
                    devices: devices,
                },
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        })
}

export const saveDeviceName = (auth, imei, name) => async (dispatch) => {
    dispatch(beginApiCall())
    deviceApi
        .saveDeviceName(auth, {imei, name})
        .then((devices)=>{
            dispatch({
                type: types.SAVE_DEVICE_NAME_SUCCESS,
                payload: {
                    devices: devices,
                }
            })
        })
        .catch((error)=>{
            dispatch(apiCallError(error))
        })
}