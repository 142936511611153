import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux"
import { SortedTable } from "../../common/SortedTable"
import SortButton from "../../common/SortButton";
import Confirmation from "../../common/Confirmation";
import EditIcon from "../../common/icons/pen-to-square-solid.svg";
import DeleteIcon from "../../common/icons/trash-can-solid.svg";
import { deleteEndpoint, fetchEndpoints, updateEndpoint } from "../../../actions/endpointActions";
import UpdateEndpoint from "./UpdateEndpoint"


const EndpointList = ({ auth, endpoints, customers }) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const { list, requestSort, sortConfig } = SortedTable(endpoints)
    const [selectedEndpoint, setSelectedEndpoint] = useState(null)


    const handleClose = () => {
        setShow(false);
        dispatch(fetchEndpoints(auth));
    };

    const removeEndpointHandler = (e) => {
        dispatch(deleteEndpoint(auth, e))
        setShow(false);
    }

    const updateEndpointHandler = (e) => {
        dispatch(updateEndpoint(auth, e))
        handleClose()
    }

    const showModalHandler = (e) => {
        setShow(true);
        setSelectedEndpoint(
            endpoints.find((endpoint) => {
                return endpoint.Id === e;
            })
        );
    };
    return (
        <>
            <Table striped>
                <thead className="thead-inverse">
                    <tr>
                        <th>
                            Customer
                            <SortButton
                                name="Customer"
                                requestSort={requestSort}
                                sortConfig={sortConfig} />
                        </th>
                        <th>
                            Name
                            <SortButton
                                name="Name"
                                requestSort={requestSort}
                                sortConfig={sortConfig} />
                        </th>
                        <th>
                            URL
                            <SortButton
                                name="URL"
                                requestSort={requestSort}
                                sortConfig={sortConfig} />
                        </th>
                        <th>
                            Protocol
                            <SortButton
                                name="Protocol"
                                requestSort={requestSort}
                                sortConfig={sortConfig} />
                        </th>
                        <th>
                            Devices
                            <SortButton
                                name="Devices"
                                requestSort={requestSort}
                                sortConfig={sortConfig} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {list.length === 0 ? (
                        <tr>
                            <td colSpan={15} style={{ textAlign: "center" }}>
                                No endpoints found
                            </td>
                        </tr>
                    ) : (list.map((endpoint) => {
                        const isViewer = auth.user.Role === "Viewer"
                        const customerName = customers.find((c) => c.Id == endpoint.CustomerId).Name
                        return (
                            <tr className="table-row" key={endpoint.Id}>
                                <td>{customerName}</td>
                                <td>{endpoint.Name}</td>
                                <td>{endpoint.URL}</td>
                                <td>{endpoint.Protocol}</td>
                                <td>{endpoint.Devices ? endpoint.Devices : 0}</td>
                                <td>
                                    {!isViewer && (
                                        <Button className="shadow-sm border-secondary" variant="light" onClick={() => {
                                            showModalHandler(endpoint.Id)
                                        }}>
                                            <img
                                                src={EditIcon}
                                                alt="Edit"
                                            />
                                        </Button>
                                    )}
                                </td>
                                <td>
                                    {!isViewer && (
                                        <Button className="shadow-sm border-secondary" variant="light">
                                            <Confirmation
                                                buttonText={
                                                    <img
                                                        src={DeleteIcon}
                                                        alt="Delete"
                                                    />
                                                }
                                                description={`Are you sure you want to remove this endpoint?`}
                                                onConfirm={() => removeEndpointHandler(endpoint)}
                                            />
                                        </Button>
                                    )}
                                </td>
                            </tr>
                        )
                    })
                    )}
                </tbody>
            </Table>
            {selectedEndpoint && (
                <UpdateEndpoint
                    show={show}
                    setShow={setShow}
                    endpoint={selectedEndpoint}
                    customers={customers}
                    updateEndpointHandler={updateEndpointHandler}
                />
            )}
        </>
    )
}

EndpointList.propTypes = {
    auth: PropTypes.object.isRequired,
    endpoints: PropTypes.array.isRequired,
    customers: PropTypes.array.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        endpoints: state.endpoints,
        customers: state.customers
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EndpointList)