import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

// Redux
import {connect, useDispatch} from "react-redux";
import {
    fetchForwards,
    fetchForwardsByCustomer,
    fetchMessageTypes,
    fetchProtocols,
    loadSearchedForwards,
    saveForward, saveIntegration,
} from "../../actions/forwardActions";

// Components
import ForwardList from "./components/ForwardList";
import Spinner from "../common/Spinner";

// Styling
import CreateForward from "./components/CreateForward";
import {fetchCustomers} from "../../actions/customerActions";
import LazySearchBar from "../common/LazySearchBar";
import {getForwardsCount} from "../../api/forwardApi";
import {apiCallError} from "../../actions/apiStatusActions";
import CreateIntegration from "./components/CreateIntegration";

const ForwardPage = ({auth, forwards, customers, apiCallsInProgress}) => {
    const dispatch = useDispatch();
    //const forwardList = [...forwards];
    const [showForward, setShowForward] = useState(false);
    const [showIntegration, setShowIntegration] = useState(false);
    const [resultCount, setResultCount] = useState(0);
    const filters = ["Name", "IP", "Port"];

    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        Updateresultcount(auth);
    }, [forwards]);

    const Updateresultcount = (auth) => {
        getForwardsCount(auth)
            .then((resultCount) => {
                setResultCount(resultCount);
            })
            .catch((error) => {
                dispatch(apiCallError(error));
            });
    };

    const refresh = () => {
        if (apiCallsInProgress === 0) {
            dispatch(fetchForwards(auth));
            dispatch(fetchCustomers(auth));
            dispatch(fetchProtocols(auth));
            dispatch(fetchMessageTypes(auth));
            Updateresultcount(auth);
        }
    };

    const getSearchResult = (searchTerm, filter, customer = null) => {
        dispatch(loadSearchedForwards(auth, searchTerm, filter, customer));
    };

    const setSearchActive = () => {
    };

    const getForwardsByCustomer = (customer) => {
        dispatch(fetchForwardsByCustomer(auth, customer));
    };

    const saveForwardHandler = (forward) => {
        dispatch(saveForward(auth, forward));
    };

    const saveIntegrationHandler = (integration) => {
        dispatch(saveIntegration(auth, integration));
    };

    // console.log("Render: auth", auth);
    // console.log("Render: forwards", forwards);
    // console.log("Render: customers", customers);
    // console.log("Render: apiCallsInProgress", apiCallsInProgress);

    return (
        <div className="forwards">
            <h2 className="title">Forwards </h2>
            <LazySearchBar
                filters={filters}
                refresh={refresh}
                customers={customers}
                resultCount={resultCount}
                getSearchResult={getSearchResult}
                setSearchActive={setSearchActive}
                getObjectByCustomer={getForwardsByCustomer}
                apiCallsInProgress={apiCallsInProgress}
            />
            <div className="create-object-div">
                <CreateForward
                    show={showForward}
                    setShow={setShowForward}
                    saveForward={saveForwardHandler}
                />
                <CreateIntegration
                    show={showIntegration}
                    setShow={setShowIntegration}
                    saveIntegration={saveIntegrationHandler}
                />
            </div>
            {forwards.length === 0 && apiCallsInProgress > 0 ? (
                <Spinner/>
            ) : (
                <div>
                    {apiCallsInProgress === 0 ? (
                        <>
                            <ForwardList forwards={forwards}/>
                        </>
                    ) : (
                        <Spinner/>
                    )}
                </div>
            )}
        </div>
    );
};

ForwardPage.propTypes = {
    auth: PropTypes.object.isRequired,
    forwards: PropTypes.array.isRequired,
    customers: PropTypes.array.isRequired,
    apiCallsInProgress: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        forwards: state.forwards,
        customers: state.customers,
        apiCallsInProgress: state.apiCallsInProgress,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ForwardPage);
