import React, {useEffect, useState} from "react";
import * as PropTypes from "prop-types";
import {useDispatch, connect} from "react-redux";
import {
    HttpTransportType,
    HubConnectionState,
    HubConnectionBuilder,
    JsonHubProtocol,
    LogLevel
} from "@microsoft/signalr";
import Spinner from "../../common/Spinner";
import DeviceSearch from "./components/DeviceSearch";
import {
    GetAllDeviceTestSettings,
    saveDeviceTestSettingsAction,
    SetCurrentDeviceTestActive,
    SetCurrentDeviceTestInActive,
    updateDeviceTestSettingsAction
} from "../../../actions/deviceTestSettingsAction";
import {
    dispatchDeviceRawMsgToState,
    getDeviceState,
    getLastRawmessagesByDevice,
    loadDevices
} from "../../../actions/deviceActions";
import {fetchCustomers} from "../../../actions/customerActions";
import {fetchConfigurationGroups} from "../../../actions/configurationGroupActions";
import {loadActiveCanConfigs} from "../../../actions/canConfigActions";
import DeviceSettingsModal from "./components/DeviceSettingsModal";
import {handleError} from "../../../api/apiUtils";
import DeviceTest from "./components/DeviceTest";


const DeviceTestPage = ({auth, devices, rawMessages, deviceTestSettings, apiCallsInProgress}) => {
    const dispatch = useDispatch();
    const baseUrl = process.env.SOCKET_URL + 'devicetests/ws';

    //Bevat de websocket connection
    const [connection, setConnection] = useState(null);

    //Laat de daadwerkelijke settingsmodal voor een device zien
    const [showSettingsModal, setShowSettingsModal] = useState(false);

    //Of de daadwerkelijke pagina geladen moet worden waar een device afgetest word
    const [showDeviceTests, setShowDeviceTests] = useState(false);

    //List van devices die terugkomen als er minimaal 3 letters ingetypt worden in de zoekbalk
    const [deviceList, setDeviceList] = useState([])

    //Array met alle tests voor dat imei
    const [deviceTestData, setDeviceTestData] = useState([]);
    
    //Laatste deviceData van het device vanuit de preprocessor
    const [lastDeviceMessage, setLastDeviceMessage] = useState({
        Imei: "",
        RawMessage: {},
        DeviceState: {},
    })

    // const [deviceInTest, setDeviceInTest] = useState({});

    //Huidige input voor device settings worden geset in Modal
    const [deviceInputSettings, setDeviceInputSettings] = useState({
        ConfigurationGroupId: "",
        Protocol: "",
        Imei: "",
        UserId: "",
        CustomerId: "",
    });

    const handleDeviceSettingsModalClose = () => {
        setShowSettingsModal(false);
        // setDeviceInputSettings({});
    }

    const handleDeviceTestPageClose = () => {
        //Closing test maar kan halverwege ook afgebroken worden via deze functie
        setShowDeviceTests(false);
        closeConnection().then(() => {});
    }

    useEffect(() => {
        refresh();
    }, [])

    useEffect(() => {
        if (connection) {
            startSignalRConnection(connection)
                .then(() => {
                    InitTestSocket(connection).then(() => {
                    });
                })
                .catch(handleError);
        }
    }, [connection])
    
    useEffect(() => {
        if (lastDeviceMessage) {
            GetTestProgress(connection, lastDeviceMessage)
        }
    }, [lastDeviceMessage])

    useEffect(() => {
        if (deviceTestData.length !== 0 && 
            deviceTestData.filter(
                (deviceTest) => checkTestState(deviceTest)
            ).length === deviceTestData.length) 
        {
            //Test wordt afgebroken zodra alle tests zijn afgerond.
            closeConnection().then(() => {});
        }

        function checkTestState(deviceTest) {
            return deviceTest.state === 1 || deviceTest.state === 3
        }
    }, [deviceTestData])

    const refresh = () => {
        dispatch(loadDevices(auth));
        dispatch(GetAllDeviceTestSettings(auth));
    };

    //Wordt aangeroepen als de settings van de modal gesaved worden.
    //Als de settings al bestaan en gewijzigd worden, moet de huidige device settings geupdate worden in plaats van een nieuwe save aanroepen
    const saveDeviceTestSettingsToDb = () => {
        setShowSettingsModal(false);
        //if deviceSettings al bestaan in de database/State dan updaten 
        //update devicesettings in de database en in de state
        //else de nieuwe settings saven

        let existingDevTestSettings = deviceTestSettings.find(devTestSetting => devTestSetting.Imei === deviceInputSettings.Imei);
        
        if (existingDevTestSettings) {
            //Settings bestaan al in de state dus zijn al een keer aangemaakt en moeten geupdate worden in plaats van aangemaakt worden.
            dispatch(updateDeviceTestSettingsAction(auth, existingDevTestSettings.Id, deviceInputSettings))
            existingDevTestSettings = {};
        } else {
            dispatch(saveDeviceTestSettingsAction(auth, deviceInputSettings));
        }
    }

    //Opent de settingsModal waar een device geconfigureerd kan worden met settings
    const openConfigurationSettingsModal = (device) => {
        dispatch(fetchCustomers(auth));
        dispatch(loadActiveCanConfigs(auth));
        dispatch(fetchConfigurationGroups(auth));

        setShowSettingsModal(true);

        let existingDevTestSettings = deviceTestSettings.find(devTestSetting => devTestSetting.Imei === device.IMEI);

        if (existingDevTestSettings) {
            setDeviceInputSettings({
                ConfigurationGroupId: existingDevTestSettings.ConfigurationGroupId,
                Protocol: existingDevTestSettings.CanProtocol,
                Imei: device.IMEI,
                UserId: auth.user.Id,
                CustomerId: existingDevTestSettings.CustomerId,
            })
            existingDevTestSettings = {};
        } else {
            setDeviceInputSettings({
                Imei: device.IMEI,
                UserId: auth.user.Id,
            })
        }

        // OnClick methode voor deviceSearch waarbij de huidige configuratie uit de database gehaald wordt
        // Als deze nog niet bestaat wordt deze bij het saven van de config settings in de database aangemaakt en in de state gezet
        // Mocht deze dan nog veranderen door de monteur moet de database geupdate worden voordat de test gestart wordt.
    }

    //Start het daadwerkelijke testen van een device als de settings correct geset zijn
    const startDeviceTesting = (deviceInputSetting) => {
        let deviceInput =
            deviceInputSettings.ConfigurationGroupId &&
            deviceInputSettings.CustomerId &&
            deviceInputSettings.Imei &&
            deviceInputSettings.Protocol &&
            deviceInputSettings.UserId
                ? deviceInputSettings 
                : deviceInputSetting

        dispatch(getDeviceState(auth, deviceInput.Imei));
        dispatch(SetCurrentDeviceTestActive(auth, deviceInput.Imei));
        dispatch(getLastRawmessagesByDevice(auth, deviceInput.Imei));

        setConnection(initSignalRConnection(deviceInput.Imei));

        setDeviceInputSettings({
            ConfigurationGroupId: deviceInput.ConfigurationGroupId,
            Protocol: deviceInput.Protocol,
            Imei: deviceInput.Imei,
            UserId: deviceInput.UserId,
            CustomerId: deviceInput.CustomerId,
        });

        setShowSettingsModal(false);
        setShowDeviceTests(true);

        //Zodra de monteur de settings geset heeft en het inbouwen gelukt is moet hij op start test drukken
        //Op dat moment wordt deze methode afgetrapt en zal een socket geopend moeten worden naar de api waar testdata
        //doorheen gecommuniceerd zal moeten worden
    }
    
    // const saveDeviceTest = () => {
    //     let ValidConnection = deviceTestData.find((devTest) => devTest.title === "Valid Connection");
    //     let ValidTimeStamp = deviceTestData.find((devTest) => devTest.title === "Valid UnitTime");
    //     let ValidIGNState = deviceTestData.find((devTest) => devTest.title === "Valid Ignition State");
    //     let ValidPosition = deviceTestData.find((devTest) => devTest.title === "Valid Position");
    //     let ValidOdo = deviceTestData.find((devTest) => devTest.title === "Validate Odo State");
    //     let ValidVIN = deviceTestData.find((devTest) => devTest.title === "Validate VIN");
    //     let ValidIbutton = deviceTestData.find((devTest) => devTest.title === "Validate IButton");
    //     let ValidIO1State = deviceTestData.find((devTest) => devTest.title === "Validate Private Switch");

    //     let deviceMsg = JSON.parse(lastDeviceMessage);

    //     let devicetest = {
    //         IMEI: deviceInputSettings.Imei,
    //         CustomerId: deviceInputSettings.CustomerId,
    //         UserId: deviceInputSettings.UserId,
    //         TimeStamp: deviceMsg.DeviceState.DateTime,
    //         ValidConnection: ValidConnection ? ValidConnection.state === 1 : false,
    //         ValidTimeStamp: ValidTimeStamp ? ValidTimeStamp.state === 1 : false,
    //         ValidIGNState: ValidIGNState ? ValidIGNState.state === 1 : false,
    //         ValidPosition: ValidPosition ? ValidPosition.state === 1 : false,
    //         LAT: deviceMsg.DeviceState.LAT,
    //         LON: deviceMsg.DeviceState.LON,
    //         SatCount: deviceMsg.DeviceState.SATCount,
    //         ValidOdo: ValidOdo ? ValidOdo.state === 1 : false,
    //         Odo: deviceMsg.DeviceState.odo ?? null,
    //         ValidVIN: ValidVIN ? ValidVIN.state === 1 : false,
    //         VIN: deviceMsg.DeviceState.VIN,
    //         IbuttonActive: ValidIbutton ? ValidIbutton.state === 1 : false,
    //         ValidIbutton: ValidIbutton ? ValidIbutton.state === 1 : false,
    //         Ibutton: deviceMsg.DeviceState.KID,
    //         PrivateSwitchActive: deviceMsg.DeviceState.IO1State,
    //         ValidIO1State: ValidIO1State ? ValidIO1State.state === 1 : false,
    //     }

    //     dispatch(saveForward(auth, devicetest));
    // }

    // ===================================== Websockets part =====================================

    function initSignalRConnection(IMEI) {
        const protocol = new JsonHubProtocol();
        const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;

        const options = {
            transport,
            logMessageContent: true,
            logger: LogLevel.Debug,
        };

        const connection = new HubConnectionBuilder()
            .withUrl(baseUrl + `?IMEI=${IMEI}`, options)
            .withHubProtocol(protocol)
            .build();

        connection.on("ReceiveTestList", (res) => {
            setDeviceTestData(res);
        });
        
        connection.on("ReceiveRawMessage", (res) => {
            let result = JSON.parse(res);
            setLastDeviceMessage(res);
            dispatch(dispatchDeviceRawMsgToState(result.RawMessage));
        })

        connection.on("ReceiveTestProgress", (res) => {
            setDeviceTestData(res);
        })

        connection.onclose = () => {
            connection.stop();
        }

        return connection;
    }

    function startSignalRConnection(connection) {
        return connection.start({
            withCredentials: true,
        });
    }

    async function InitTestSocket(connection) {
        if (connection._connectionState === HubConnectionState.Connected) {
            try {
                await connection.send('InitTestSocket', deviceInputSettings.Imei);
            } catch (e) {
                handleError(e);
            }
        }
    }

    async function GetTestProgress(connection, lastDeviceMessage) {
        if (connection) {
            try {
                if (connection._connectionState === HubConnectionState.Connected) {
                    await connection.send('GetTestProgress', {
                        IMEI: deviceInputSettings.Imei,
                        SocketModel: deviceTestData,
                        LastDeviceMessage: lastDeviceMessage,
                    });
                }
            } catch (e) {
                handleError(e);
            }
        }
    }

    async function checkPendingCondition(valueCheck) {
        let deviceTD = JSON.parse(JSON.stringify(deviceTestData));

        let newDeviceTestData = deviceTD.map(obj => {

            if (obj.state === 4) {
                obj.value = valueCheck;
            }
            return obj;
        })

        await connection.send('GetTestProgress', {
            IMEI: deviceInputSettings.Imei,
            SocketModel: newDeviceTestData,
            LastDeviceMessage: lastDeviceMessage,
        })
    }

    async function closeConnection() {
        if (connection) {
            try {
                // saveDeviceTest();
                dispatch(SetCurrentDeviceTestInActive(auth, deviceInputSettings.Imei))
                // setDeviceInTest({});
                await connection.onclose();
            } catch (e) {
                handleError(e);
            }
        }
    }

    return (
        <>
            {devices.length === 0 && apiCallsInProgress > 0 ? (
                <Spinner/>
            ) : (
                <>
                    {
                        showDeviceTests ? (
                            <>
                                <DeviceTest
                                    rawMessages={rawMessages}
                                    deviceTestData={deviceTestData}
                                    deviceInputSettings={deviceInputSettings}
                                    checkPendingCondition={checkPendingCondition}
                                    handleDeviceTestPageClose={handleDeviceTestPageClose}/>
                            </>
                        ) : (
                            showSettingsModal ? (
                                <>
                                    <DeviceSettingsModal
                                        deviceInputSettings={deviceInputSettings}
                                        showSettingsModal={showSettingsModal}
                                        startDeviceTesting={startDeviceTesting}
                                        setDeviceInputSettings={setDeviceInputSettings}
                                        saveDeviceTestSettingsToDb={saveDeviceTestSettingsToDb}
                                        handleDeviceSettingsModalClose={handleDeviceSettingsModalClose}/>
                                </>
                            ) : (
                                <DeviceSearch
                                    devices={devices}
                                    deviceTestSettings={deviceTestSettings}
                                    deviceList={deviceList}
                                    setDeviceList={setDeviceList}
                                    startDeviceTesting={startDeviceTesting}
                                    openConfigurationSettingsModal={openConfigurationSettingsModal}/>
                            )
                        )
                    }
                </>
            )}
        </>
    );
};

DeviceTestPage.propTypes = {
    auth: PropTypes.object.isRequired,
    devices: PropTypes.array.isRequired,
    deviceTestSettings: PropTypes.array.isRequired,
    apiCallsInProgress: PropTypes.number.isRequired,
    rawMessages: PropTypes.array.isRequired,
    
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        devices: state.devices,
        rawMessages: state.deviceDetails.rawMessages,
        deviceTestSettings: state.deviceTestSettings,
        apiCallsInProgress: state.apiCallsInProgress
    }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceTestPage);