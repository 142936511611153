import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function deviceReducer(state = initialState.devices, action) {
    switch (action.type) {
        case types.LOAD_DEVICES_SUCCESS:
            return action.payload.devices;
        case types.LOAD_FIRST_BATCH_DEVICES_SUCCESS:
            return action.payload.devices;
        case types.LOAD_BATCH_DEVICES_SUCCESS: {
            let result = [...state];

            action.payload.devices.map((item) => {
                result = [...result, item]
            });
            
            return result;
        }
        case types.SAVE_DEVICE_CUSTOMER_CONNECTIONS_SUCCESS:
            var newIds = action.payload.devices.map((device) => {
                return device.Id;
            });

            var filtered = [
                ...state.filter((item) => {
                    return !newIds.includes(item.Id);
                }),
            ];

            action.payload.devices.forEach((device) => {
                filtered.push(device);
            });

            return filtered;
        case types.SAVE_DEVICES_CONFIGURATION_GROUP_SUCCESS:
            var Ids = action.payload.companyCard.Devices.map((device) => {
                return device.Id;
            });

            var filteredDevices = [
                ...state.filter((item) => {
                    return !Ids.includes(item.Id);
                }),
            ];

          action.payload.companyCard.Devices.forEach((device) => {
                filteredDevices.push(device);
            });
            return filteredDevices;
        case types.LOAD_SEARCH_RESULT_DEVICES_SUCCESS:
            return action.payload.devices;
        case types.SAVE_DEVICE_NOTE_SUCCESS:
            // console.log(action.payload)
            return action.payload.devices;
        case types.LOGOUT:
            return initialState.devices;
        default:
            return state;
    }
}
