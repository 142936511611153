import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Redux
import { useDispatch, connect } from "react-redux";
import {
  fetchAuthServers,
  loadAuthServersByCustomer,
  loadSearchedAuthServers, saveAuthServer,
} from "../../actions/authServerActions";

import { Card } from "react-bootstrap";
import Spinner from "../common/Spinner";
import AuthServerList from "./components/AuthServerList";
import CreateAuthServer from "./components/CreateAuthServer";
import LazySearchBar from "../common/LazySearchBar";
import { fetchCustomers } from "../../actions/customerActions";
import * as authServerApi from "../../api/authServerApi";
import { apiCallError } from "../../actions/apiStatusActions";

const AuthServerPage = ({
  auth,
  customers,
  authServers,
  apiCallsInProgress,
}) => {
  const dispatch = useDispatch();
  const authServerList = [...authServers];
  const [show, setShow] = useState(false);
  const [resultCount, setResultCount] = useState(0);
  const filters = ["Name", "IP", "Forward"];

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    if (apiCallsInProgress === 0) {
      dispatch(fetchCustomers(auth));

      authServerApi
        .CountTotalRows(auth)
        .then((resultCount) => {
          setResultCount(resultCount);
        })
        .catch((error) => {
          apiCallError(error);
        });

      dispatch(fetchAuthServers(auth));
    }
  };

  const getSearchResult = (searchTerm, filter, customer = null) => {
    dispatch(loadSearchedAuthServers(auth, searchTerm, filter, customer));
  };

  const setSearchActive = () => {};

  const getAuthServersByCustomer = (customer) => {
    dispatch(loadAuthServersByCustomer(auth, customer));
  };

  const onSubmit = (values) => {
    // console.log(values);
    dispatch(saveAuthServer(auth, values));
    setShow(false);
  }

  return (
    <div className="authServer">
      <h2 className="title">Authentication Server</h2>
      <LazySearchBar
        filters={filters}
        refresh={refresh}
        customers={customers}
        resultCount={resultCount}
        getSearchResult={getSearchResult}
        setSearchActive={setSearchActive}
        apiCallsInProgress={apiCallsInProgress}
        getObjectByCustomer={getAuthServersByCustomer}
      />
      <div className="create-object-div">
        <CreateAuthServer show={show} setShow={setShow} onSubmit={onSubmit} />
      </div>
      <Card>
        {authServers.lenght != 0 ? (
          <AuthServerList authServers={authServerList} />
        ) : (
          <Spinner />
        )}
      </Card>
    </div>
  );
};

AuthServerPage.propTypes = {
  auth: PropTypes.object.isRequired,
  customers: PropTypes.array.isRequired,
  authServers: PropTypes.array.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
  forwards: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    customers: state.customers,
    authServers: state.authServers,
    apiCallsInProgress: state.apiCallsInProgress,
    forwards: state.forwards,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AuthServerPage);
