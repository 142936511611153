import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const deviceForwardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_DEVICE_FORWARDS_SUCCESS:
      return action.payload.forwards;
    case types.SAVE_DEVICE_FORWARD_SUCCESS:
      return [
        ...state.filter(forward => forward.MessageType !== action.payload.forwards.MessageType),
        action.payload.forwards,
      ];
    case types.UPDATE_DEVICE_FORWARD_SUCCESS:
      return [
        ...state.filter(forward => forward.Id !== action.payload.forwards.Id),
        action.payload.forwards,
      ];

    case types.REMOVE_DEVICE_FORWARD_SUCCESS:
      return [
        ...state.filter(forward => forward.Id !== action.payload.Id),
        action.payload.emptyForward];
    default:
      return state;
  }
};

export default deviceForwardReducer;
