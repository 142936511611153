import * as Yup from "yup";

const EndpointValidationSchema = Yup.object().shape({
    CustomerId: Yup.string()
        .required("Customer is required")
        .test(
            "CustomerId",
            "No Customer selected",
            (value) => value && value !== "null" && value.length > 0
        ),
    Name: Yup.string()
        .min(3, "Name must be at least 3 characters")
        .required("Name is required"),
    URL: Yup.string()
        .required("URL is required"),
    Protocol: Yup.number()
        .required("Protocol is required")
        .test(
            "Unknown protocol",
            (value) => value && value == 1 || value == 3
        )
})

export default EndpointValidationSchema
