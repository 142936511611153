import { ErrorMessage, Field, Formik, useFormik } from "formik";
import React from "react"
import { Button, Modal, Form } from "react-bootstrap"
import PropTypes from "prop-types";
import { connect } from "react-redux";
import EndpointValidationSchema from "../validation/EndpointValidationSchema";

const UpdateEndpoint = ({ show, setShow, endpoint, customers, updateEndpointHandler }) => {
    const handleClose = () => setShow(false);

    const initialValues = {
        Id: endpoint.Id,
        CustomerId: endpoint.CustomerId,
        Name: endpoint.Name,
        URL: endpoint.URL,
        Protocol: endpoint.Protocol
    }

    const onSubmit = (values) => {
        setShow(false);
        updateEndpointHandler(values);
    }

    const formik = useFormik({
        initialValues,
        updateEndpointHandler,
        onSubmit
    });

    return (
        <div>
            <Modal show={show} onHide={handleClose} centered className="text-center">
                <Modal.Header closeButton>
                    <Modal.Title>Update Endpoint</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={initialValues}
                    validationSchema={EndpointValidationSchema}
                    onSubmit={onSubmit}
                >
                    {({
                        values, errors, touched, handleSubmit
                    }) => (
                        <Form onSubmit={handleSubmit}  >
                            <Modal.Body className="text-left">
                                <Form.Label>Customer</Form.Label>
                                <Field as="select"
                                    name="CustomerId"
                                    value={values.CustomerId}
                                    className={`form-control ${touched.CustomerId && errors.CustomerId ? 'is-invalid' : ''}`}
                                >
                                    <option key={null} value={null}>
                                        --
                                    </option>
                                    {customers.map((customer) => {
                                        return (
                                            <option key={customer.Id} value={customer.Id}>
                                                {customer.Name}
                                            </option>
                                        )
                                    })}
                                </Field>
                                <ErrorMessage component="div" name="CustomerId" className="invalid-feedback" />

                                <Form.Label>Name</Form.Label>
                                <Field type="text"
                                    name="Name"
                                    value={values.Name || ""}
                                    className={`form-control ${touched.Name && errors.Name ? 'is-invalid' : ''}`}
                                    placeholder="Endpoint Name" />
                                <ErrorMessage component="div" name="Name" className="invalid-feedback" />

                                <Form.Label>URL</Form.Label>
                                <Field type="text"
                                    name="URL"
                                    value={values.URL || ""}
                                    className={`form-control ${touched.URL && errors.URL ? 'is-invalid' : ''}`}
                                    placeholder="Endpoint URL" />
                                <ErrorMessage component="div" name="URL" className="invalid-feedback" />

                                <Form.Label>Protocol</Form.Label>
                                <Field as="select"
                                    name="Protocol"
                                    value={values.Protocol}
                                    className={`form-control ${touched.Protocol && errors.Protocol ? 'is-invalid' : ''}`}
                                >
                                    <option key={1} value={1}>
                                        HTTP
                                    </option>
                                    <option key={3} value={3}>
                                        TCP
                                    </option>
                                </Field>
                                <ErrorMessage component="div" name="CustomerId" className="invalid-feedback" />
                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    disabled={!formik.isValid}
                                >
                                    Update Endpoint
                                </Button>
                            </Modal.Footer>

                        </Form>
                    )}


                </Formik>
            </Modal>
        </div>
    )
}

UpdateEndpoint.propTypes = {
    auth: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    customers: PropTypes.array.isRequired,
    updateEndpointHandler: PropTypes.func.isRequired,
    endpoint: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    }
}
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEndpoint)